<div #cartListElement >
    <mibp-loader *ngIf="( !cart || ( cart && (!ecomItems || ecomItems?.length == 0)))  && isLoading"></mibp-loader>
    <mibp-product-list [cartId]="cart?.id" [ecomItems]="ecomItems" class="d-block"
        [hidePrice]="hidePrice"
        [hideTotalPrice]="hideTotalPrice"
        [hideAvailability]="hideAvailability"
        [hideAddToCart]="hideAddToCart"
        [hideDelete]="hideDelete"
        [hideRelatedProducts]="hideRelatedProducts"
        [hideRelatedButtons]="hideRelatedButtons"
        [priceAndAvailabilitySource]= "priceAndAvailabilitySource"
        [addItemToCartSource]="addItemToCartSource"
        (itemRemoved)="onDeleteCartItem()"
        [disableDuplicate]="disableDuplicate"
        #listItem></mibp-product-list>
    <div style="text-align:center">
        <mibp-paginator *ngIf="totalCount > pageSize" [totalItems]="totalCount" [pageSize]="pageSize" (changePage)="changePage($event)"> </mibp-paginator>
    </div>

      <div class="list-item " *ngIf="!hideQuickAdd"><span class="label_class"><label><mibp-resource-string key="Global_Add_Items_to_Template"></mibp-resource-string></label></span>
         <mibp-quick-add [skipFocus]="skipFocusOnQuickAdd" [cartId]="cart.id" [buttonTextKey]="quickAddButtonTextKey" (addedToCart)="onCartAdded($event, cart)" #quickadd></mibp-quick-add>
    </div>
</div>

