/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MediaSyncRequestJobStatus } from '../../models/media-sync-request-job-status';
import { MediaSyncRequestVmPagedResponse } from '../../models/media-sync-request-vm-paged-response';

export interface ListMediaSyncRequests$Params {
  skip?: number;
  take?: number;
  query?: string;
  from?: string;
  to?: string;
  jobStatus?: MediaSyncRequestJobStatus;
}

export function listMediaSyncRequests(http: HttpClient, rootUrl: string, params?: ListMediaSyncRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaSyncRequestVmPagedResponse>> {
  const rb = new RequestBuilder(rootUrl, listMediaSyncRequests.PATH, 'get');
  if (params) {
    rb.query('skip', params.skip, {});
    rb.query('take', params.take, {});
    rb.query('query', params.query, {});
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
    rb.query('jobStatus', params.jobStatus, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MediaSyncRequestVmPagedResponse>;
    })
  );
}

listMediaSyncRequests.PATH = '/media/sync-requests';
