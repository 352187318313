import { SuperseededProductViewModel } from './../../../../../../../mibp-openapi-gen/models/superseeded-product-view-model';
import { CartsApiController, PriceAndAvailabilityApiController, ProductsApiController } from 'root/mibp-openapi-gen/controllers';
import { BroadcastService } from './../../../../../../../services/broadcast-service/broadcast.service';
import { NoticebarService } from 'root/services/noticebar-service/noticebar.service';
import { ProductListItem, KitItem, DECIMAL_EXCEPTION, ProductListItemProduct } from './product-list-item.type';
/**
 *
 * - Will listen to Price and availability events (cartId and delivery sequence must match)
 * - Can be removed with an animation effect (LeaveTheStage)
 * - Will sum total price for the row
 * - Will check user rights and if it's an aurora company before showing price
 */
import { LocalizationService, QuickOrderLine, ContactUsService, MibpLogger, LogService, PartsCatalogueService,DuplicatePromotionProductQuantityEvent, GlobalConfigService } from 'root/services/index';
import { FormattingService } from 'root/services/formatting/formatting.service';
import { CartService, FrontendContextService } from 'root/services/index';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, ElementRef, SimpleChanges } from "@angular/core";
import { DialogButton } from 'root/components';
import { ButtonColors } from 'root/components/button/button.enum';
import { Guid } from 'guid-typescript';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { firstValueFrom, Subscription } from 'rxjs';
import { ContactUsTopic } from 'root/components/contact-dialog/contact-us-form/contact-us-form.types';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { Router } from '@angular/router';
import { AddItemToCartSource, AvailabilityType, CartItemProductStatus, PriceAndAvailabilitySource, ProductExpectedDate, ProductExpectedDateRequestDto, ProductExpectedDatesRequest, ProductType, SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';
import { KitApiController } from './../../../../../../../mibp-openapi-gen/services/kit-api-controller';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { SupportCaseItemEnquiryType } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.interface';
import { MySandvikFeatures } from 'root/services/permission';

@Component({
  selector: 'mibp-product-list-item, [mibp-product-list-item]',
  templateUrl: 'product-list-item.component.html',
  // ,styleUrls: ['product-list-item.component.scss', '../product-list-shared.scss'],
  animations: [
    trigger('deletedAnimation', [
      state('initial', style({
        backgroundColor: 'transparent'
      })),
      state('highlight', style({
        backgroundColor: '#ffff99'
      })),
      state('final', style({
        opacity: 0,
        height: 0
      })),
      transition('initial=>highlight', animate('250ms')),
      transition('highlight=>final', animate('250ms')),
      transition('final=>initial', animate('250ms'))
    ]),
    trigger('addedAnimation', [
      state('initial', style({
        opacity: 1,
        backgroundColor: 'transparent'
      })),
      state('final', style({
        backgroundColor: '#ffff99',
        opacity: 0
      })),
      transition('initial=>final', animate('0ms')),
      transition('final=>initial', animate('750ms'))
    ])
  ]
})
export class ProductListItemComponent implements OnInit, OnDestroy, OnChanges {
  productListitem: ProductListItem;
  @Input() productExpectedDate : ProductExpectedDate;
  @Input() hidePrice = false;
  @Input() hideTotalPrice = false;
  @Input() hideAvailability = false;
  @Input() hideRelatedProducts: boolean;
  @Input() hideAction: boolean;
  @Input() hideAddToCartAction: boolean;
  @Input() hideAddToCart = true;
  @Input() hideAddToCartBig = true;
  @Input() hideRelatedButtons: boolean;
  @Input() showRecommendedQuantity: boolean;
  @Input() equipment: any[];
  @Input() index: any;
  @Input() cartId: Guid;
  @Output() itemRemoved = new EventEmitter<Guid>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() validDecimalQuantity = new EventEmitter<boolean>();
  @Input() priceAndAvailabilitySource: PriceAndAvailabilitySource;
  @Input() addItemToCartSource =  AddItemToCartSource.QuickAdd;
  @Input() isNarrowLayout = false;
  @Input() isActiveCart = false;
  @Input() disableDuplicate = false;
  @Input() showAvailableQuantity = false;
  /**
   * Will hide delete button even if itemId exists
   */
  @Input() hideDelete: boolean;
  @Input() newProductCode: string;
  @Input() isDecimalUnit: boolean;

  protected cartItemProductStatuses = CartItemProductStatus;

  // @ViewChild('template', {static: true}) template;
  log: MibpLogger;
  isAuroraCompany = false;
  activeDeliverySequence: SessionDeliverySequenceViewModel;
  quantityForm: UntypedFormGroup;
  machineLinkForm: UntypedFormGroup;
  hasCreateCartPermission: boolean;
  isLoading: boolean;
  isUpdatingQuantity: boolean;
  errorMessageResourceKey: string;
  userCanSeeAvailability: boolean;
  relatedkitItems=null;
  removalPending = false;
  updatePending = false;
  currentState = 'initial';
  addedState = 'initial';
  currencyCode = '';
  isAllowedToSeePrice = false;
  confirmDeleteButtons: DialogButton[];
  stopUsingResources: () => void;
  machineLinkOpen = false;
  machineLinkSuccessText: string;
  machineLinkErrorText: string;
  machineUnlinkSuccessText: string;
  kitItemsLoading: boolean;
  showKitItems: boolean;
  mediaIsSmallOrLarger = false;
  responsiveSub: Subscription;
  clickForDetailsText = "";
  isLoadingExpectedDate = false;
  availablePromotionQuantity = 0;
  private changeQuantityTimer = null;
  isOutPhased = false;
  contactUsMessageRequestOutPhasedItemReplacement: string;
  contactUsMessageRequestAvailability: string;
  contactUsMessageRequestPrice: string;
  contactUsMessageExpectedDate:string;
  localizationSubscription: Subscription;
  useexpectedDeliveryDateAndAvailability: boolean;
  superseededProductSubscription: Subscription;
  deliverySequenceSubscription: Subscription;
  promotionNoticeMacro: { [ key: string ]: any };
  duplicatePromotionProductSubscription: Subscription;
  contactUsItemEquiryFormEnabled: boolean;

  protected isDeliverySequenceRequired = true;
  protected isProductMissingOrRestricted = false;
  protected isGlobalProductUnavailable = false;

  @Input()
  set ecomItem(value: ProductListItem) {

    this.productListitem = this.sanitizeProductListItem(value);
    this.refreshUI();

    if (!this.productListitem.quantity) {
      this.productListitem.quantity = 1;
    }
  }

  notImplemented(): void {
    alert("Not implemented");
  }

  get isActingAs(): boolean {
    return !!this.activeDeliverySequence;
  }

  private sanitizeProductListItem(value: ProductListItem) {

    if (value.product) {

      // cartItemProductStatus is currently just used for persistent carts
      this.isProductMissingOrRestricted = value.cartItemProductStatus == CartItemProductStatus.CompanyProductMissing || value.cartItemProductStatus == CartItemProductStatus.CompanyProductRestricted;
      this.isGlobalProductUnavailable = value.cartItemProductStatus == CartItemProductStatus.GlobalProductMissing;


      value.product.brandCode = value.product.brandCode || '';
      value.product.brandDescription = value.product.brandDescription || '';
      value.product.unitOfMeasure = value.product.unitOfMeasure || '';
      value.documotoRoute = this.partsCatalogueService.mapDocumotoDeepUrlToRoute(value.documotoDeepUrl);
      if(value.promotionProductQuantity && value.promotionProductQuantity.maxOrderableQuantity){
        this.availablePromotionQuantity = value.promotionProductQuantity.maxOrderableQuantity - value.promotionProductQuantity.orderedQuantity;
        if((!value.promotionProductQuantity.cumulativeDuplicateProductQuantity && value.quantity > this.availablePromotionQuantity) || (value.promotionProductQuantity.cumulativeDuplicateProductQuantity > this.availablePromotionQuantity)){
          value.showPromotionRestrictionNotice = true;
        }
        this.promotionNoticeMacro = {'0': this.availablePromotionQuantity};
      }
    }

    return value;

  }



  constructor(private fb: UntypedFormBuilder,
    private cartService: CartService,
    private productsApi: ProductsApiController,
    private broadcast: BroadcastService,
    private element: ElementRef,
    private frontEndContext: FrontendContextService,
    private formattingService: FormattingService,
    private localizationService: LocalizationService,
    private contactUsService: ContactUsService,
    private notifications: NoticebarService,
    private partsCatalogueService: PartsCatalogueService,
    logger: LogService,
    private kitsApi: KitApiController,
    private router: Router,
    private sessionService : MibpSessionService,
    private cartsController: CartsApiController,
    private priceandavailabilityController: PriceAndAvailabilityApiController,
    private globalConfig: GlobalConfigService) {
    this.log = logger.withPrefix('product-list-item');
    this.activeDeliverySequence = this.sessionService.activeDeliverySequence;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.index) {
      // Make sure elementref has the product index attribute
      this.element.nativeElement.setAttribute('data-product-index', this.index);
    }

    if(changes.newProductCode && changes.newProductCode.currentValue && changes.newProductCode.currentValue !== this.productListitem.product.code){
      const superseededByProductCode = this.newProductCode;
      const superseededProductCode = this.productListitem.product.code;

      // For persistant cart we do not use supersession from P&A
      if (this.sessionService.hasFeature(MySandvikFeatures.ShopPersistentCart)) {
        this.GetExpectedDeliveryDateOfNewProduct();
        return;
      }

      const superseededCodes : SuperseededProductViewModel[] = [<SuperseededProductViewModel>{superseededByProductCode: superseededByProductCode, superseededProductCode:superseededProductCode}];
      this.cartsController.replaceProductsWithSuperseededProductsFromPandA({body:superseededCodes, cartId: this.cartId?.toString()}).toPromise().then(() => {
        this.UpdateProductWithSuperseededProduct(this.newProductCode);
        if(this.useexpectedDeliveryDateAndAvailability) {
          this.GetExpectedDeliveryDateOfNewProduct();
        }
      }).catch(error => {
        this.log.error(`Could not replace products with superseeded products from q and a`, error);
        if(this.useexpectedDeliveryDateAndAvailability) {
          this.GetExpectedDeliveryDateOfNewProduct();
        }
      });
    }
  }

  priceError(): void {
    this.disableQuantity(false);
  }

  priceReceived(): void {
    this.disableQuantity(false);
  }

  GetExpectedDeliveryDateOfNewProduct(): void {
    this.productListitem.productExpectedDate = null;
    const todayDate = new Date().toLocaleDateString('en-ca');
    const product = {
      quantity:this.productListitem.quantity, productCode: this.newProductCode || this.productListitem.superseededByProductCodeDbValue || this.productListitem.product.code
    } as ProductExpectedDateRequestDto;
    firstValueFrom(this.priceandavailabilityController.getProductExpectedDates({body:{
      products : [product],
      priceAndAvailabilitySource : PriceAndAvailabilitySource.ProductSearch,
      deliverySequenceId : null,
      userCurrentDateTime:todayDate,
      onlyGetFromCache: false
    } as ProductExpectedDatesRequest})).then(x => {
      this.productListitem.productExpectedDate = x[0];
      this.productListitem.expectedDate = x[0].expectedDeliveryDate;
      this.isLoadingExpectedDate = false;
    }).catch(error=>{
      this.isLoadingExpectedDate = false;
      this.productListitem.productExpectedDate = {
        productCode: product.productCode,
        availabilityType: AvailabilityType.NotAvailable,
        quantity: product.quantity,
        errorCode: 'UNHANDLED',
        errorDescription: '',
        expectedDeliveryDate: null
      };
      this.log.error('Error fetching expected dates', error);
    });
  }

  UpdateProductWithSuperseededProduct(superseededProductCode : string) : void {
    this.isOutPhased = superseededProductCode == 'OUT_PHASED_ITEM';
    this.superseededProductSubscription = this.productsApi.getProductLocalThenGlobal({ productCode: superseededProductCode})
      .subscribe({
        next: dbProduct => {
          if (dbProduct) {
            this.productListitem.isSuperseeded = true;
            this.productListitem.product.id = Guid.parse(dbProduct.id);
            this.productListitem.product.name = dbProduct.name;
            this.productListitem.product.PartsPictureImageUrl = dbProduct.imageUrl || 'assets/images/NewDefaultImage.png';
            this.productListitem.product.brandDescription = dbProduct.brandDescription;
            this.productListitem.product.type = this.getProductTypeName(dbProduct.itemType);
            this.productListitem.product.weight = dbProduct.weight;
          }
        },
        error: err => {
          this.log.error(`Error when loading superseded product`, superseededProductCode, err);
          this.productListitem.isSuperseeded = true;
          this.productListitem.product.name = superseededProductCode;
          this.productListitem.product.brandDescription = null;
          this.productListitem.product.PartsPictureImageUrl = 'assets/images/NewDefaultImage.png';
          this.productListitem.product.weight = null;
        }
      });
    this.refreshUI();
  }

  public getProductTypeName(productType: ProductType): string {
    switch (productType) {
    case ProductType.Kit:
      return 'Kit';

    case ProductType.SparePart:
      return 'SparePart';

    case ProductType.Subscription:
      return 'Subscription';

    default:
      return '';
    }
  }

  mapDocumotoDeepUrlToRoute(deepUrl: string) {
    return this.partsCatalogueService.mapDocumotoDeepUrlToRoute(deepUrl);
  }

  private refreshUI() {
    const hasActiveDeliverySequence = this.isActingAs;
    this.isAuroraCompany = this.sessionService.isAuroraCompany();
    this.currencyCode = hasActiveDeliverySequence && this.sessionService.activeDeliverySequence?.currencyCode || '';
    const hasBasePrice = this.productListitem.productPrice !== null && typeof this.productListitem.productPrice !== 'undefined';

    this.userCanSeeAvailability = this.frontEndContext.testPermission(allPermissionPolicies.canSeeAvailability);

    // Show price only if the user have the permission it's an aurora company
    this.isAllowedToSeePrice = hasActiveDeliverySequence
                               && this.frontEndContext.testPermission(allPermissionPolicies.canSeePrices);
    this.machineLinkOpen = false;

    if (this.isProductMissingOrRestricted) {
      this.disableQuantity(false);
    } else {
      this.disableQuantity(!hasBasePrice && !this.productListitem.productPrice?.errorCode);
    }
  }


  public leaveTheStage(): Promise<void> {
    return new Promise((resolve) => {

      if (this.removalPending === false) {
        // Remove was not triggered by a button click
        // Then just remove the item without fancy effects
        return resolve();
      }

      this.currentState = 'highlight';
      setTimeout(() => {
        this.currentState = 'final';
        setTimeout(() => {
          resolve();
          this.itemRemoved.emit(this.productListitem.itemId);
        }, 350);
      }, 250);
    });
  }

  addToActiveCart() {
    const products = [<QuickOrderLine>{
      productCode: this.productListitem.product.code,
      quantity: this.productListitem.quantity
    }];

    this.isLoading = true;
    this.cartService.addToCart(products, this.addItemToCartSource).then(ok => {
      this.isLoading = false;
    }).catch(err => {
      this.log.error("Could not add to active cart", err);
      this.isLoading = false;
      //
    });
  }

  /**
   * This update will trigger a new Price And Availability Event
   * So we'll just have to make sure to clear the values so a loader is show
   * then we'll wait for the event in this component
   * @see subscribeToPriceAndAvailability
   */
  onblurevent():void{
    if(this.quantityForm.controls.quantity.value==null){
      this.quantityForm.controls['quantity'].setValue(1);
      this.updateQuantity();
    }
  }

  validateQuantity(triggerEmit?: boolean): boolean {

    if (this.quantityForm.valid && !this.isDecimalUnit) {
      if (this.quantityForm.controls.quantity.value.toLocaleString().indexOf('.') != -1) {
        this.quantityForm.controls.quantity.setErrors({ DecimalValueNotSupported: true });
        this.quantityForm.controls.quantity.markAsTouched();
      }
    }

    if (triggerEmit) {
      this.validDecimalQuantity.emit(this.quantityForm.valid);
      return;
    }
    return this.quantityForm.valid;
  }

  updateQuantity(): void {

    clearTimeout(this.changeQuantityTimer);
    this.validateQuantity(true);
    if (!this.productListitem.itemId) {
      if (this.isActingAs) {

        this.productListitem.quantity = this.quantityForm.controls.quantity.value;
        if(this.productListitem.promotionProductQuantity &&
          this.productListitem.promotionProductQuantity.maxOrderableQuantity &&
          this.productListitem.quantity > this.availablePromotionQuantity)
        {
          this.productListitem.showPromotionRestrictionNotice = true;
        }
        else{
          this.productListitem.showPromotionRestrictionNotice = false;
        }
        if(this.useexpectedDeliveryDateAndAvailability){
          this.GetExpectedDeliveryDateOfNewProduct();
        }
      }

    }

    if (!this.quantityForm.invalid) {

      this.validChange.emit(true);
      if (this.quantityForm.controls.quantity.value !== this.productListitem.quantity) {

        this.isLoading = true;
        this.isUpdatingQuantity = true;
        this.disableQuantity();
        this.productListitem.price = null;
        this.isLoadingExpectedDate = true;
        this.productListitem.expectedDate = null;
        const isDuplicatePromotionProduct = this.productListitem.promotionProductQuantity?.cumulativeDuplicateProductQuantity ? true : false;
        firstValueFrom(this.cartsController.updateQuantity({cartId: this.cartId.toString() ,itemId:this.productListitem.itemId.toString(), quantity: this.quantityForm.controls.quantity.value, productCode: isDuplicatePromotionProduct ? this.productListitem.product.code: ''})).then(cumulativeDuplicateProductQuantity => {
          this.productListitem.quantity = this.quantityForm.controls.quantity.value;
          if(this.productListitem.promotionProductQuantity?.cumulativeDuplicateProductQuantity){
            this.productListitem.promotionProductQuantity.cumulativeDuplicateProductQuantity = cumulativeDuplicateProductQuantity;
            this.cartService.duplicatePromotionProductSubject.next(<DuplicatePromotionProductQuantityEvent>{productCode: this.productListitem.product.code, cumulativeDuplicateProductQuantity: cumulativeDuplicateProductQuantity});
          }
          this.isLoading = false;
          this.isUpdatingQuantity = false;
          this.handlePromotionProductQuantityUpdate();
          this.refreshUI();
          if (this.isActingAs && this.useexpectedDeliveryDateAndAvailability) {
            this.GetExpectedDeliveryDateOfNewProduct();
          }
          this.disableQuantity(false);
          if(this.isActiveCart){
            const activeCartCount = this.broadcast.cartEventValue.count;
            this.broadcast.setCartEvent({updatedItems:[this.productListitem.itemId], count:activeCartCount});
          }
        }).catch(exception => {
          this.isLoading = false;
          this.isUpdatingQuantity = false;
          this.errorMessageResourceKey = exception;
          this.updatePending = true;
          this.disableQuantity(false);
          const decimalException = exception.error.errors ? exception.error.errors.find(errorInfo => errorInfo.exceptionType === DECIMAL_EXCEPTION) : null;
          if (decimalException) {
            this.notifications.showText(decimalException.description, NoticeType.Error, false);
          }

          this.log.error('error updating quantity of item', exception);
        });
      }
    } else {
      this.validChange.emit(false);
    }
  }

  clickedRequestPriceLink() {
    if(this.globalConfig.enableNewContactUs && this.contactUsItemEquiryFormEnabled){
      this.contactUsService.openItemEnquiryContactUs(this.productListitem.product.code, this.productListitem.quantity, SupportCaseItemEnquiryType.ItemPrice);
    }
    else{
      const message = `${this.contactUsMessageRequestPrice} ${this.productListitem.product.code}`;
      this.contactUs(ContactUsTopic.RequestPrice, message);
    }

  }

  contactUs(topic: ContactUsTopic, message: string) {
    this.contactUsService.openContactUs(topic, message);
  }

  getInputWidth(){
    const currentValue = parseFloat(this.quantityForm.value.quantity);
    if(!isNaN(currentValue) && currentValue.toString().length>2){
      return '80px';
    }
    else{
      return '60px';
    }
  }
  changeQuantity(add: number) {

    clearTimeout(this.changeQuantityTimer);

    const currentValue = parseFloat(this.quantityForm.value.quantity);

    this.quantityForm.controls['quantity'].setValue(currentValue + add);

    this.changeQuantityTimer = setTimeout(() => {
      this.updateQuantity();
    }, 500);

  }

  private disableQuantity(disabled = true) {
    if(!this.isAuroraCompany){
      return;
    }

    if (!this.productListitem.itemId) {
      return;
    }

    if (!this.quantityForm) {
      return;
    }

    // If delete is hidden then we will not allow updating quantity either
    if (this.productListitem.itemId && this.hideDelete) {
      disabled = true;
    }

    if (disabled || !this.hasCreateCartPermission) {
      this.quantityForm.controls.quantity.disable();
    } else {
      this.quantityForm.controls.quantity.enable();
    }
  }



  ngOnInit(): void {
    this.contactUsItemEquiryFormEnabled = this.frontEndContext.testPermission({ features: [MySandvikFeatures.ContactUsFormItemEnquiry] });
    this.useexpectedDeliveryDateAndAvailability = this.frontEndContext.isExpectedDeliveryDateEnabled();
    this.isDeliverySequenceRequired = !this.sessionService.hasFeature(MySandvikFeatures.ShopPersistentCart);

    // this.kitsApi.getKitProducts({
    //   kitProductId: '8311708E-CBE5-40B2-11BE-08DA1C62D83E'
    // }).toPromise().then(ja => {
    //   console.warn("JA", ja);
    // }).catch(e => {
    //   console.warn("err", e);
    // });

    // Used to display price value in block or not for mobile views
    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe(result => {
      this.mediaIsSmallOrLarger = result.gteq('s');
    });

    // this.viewContainerRef.createEmbeddedView(this.template);
    this.stopUsingResources = this.localizationService.using(
      [
        'Global_ClickForDetails',
        'Carts_Item_MachineLink_CreateLink_Success',
        'Carts_Item_MachineLink_CreateLink_Error',
        'Carts_Item_MachineLink_UnLink_Success',
        'Carts_AvailabilityForProductMessage',
        'Carts_PriceForProductMessage',
        'Carts_RequestDeliveryDateMessage',
        'Carts_RequestOutPhasedItemReplacement'
      ],
      values => {
        this.clickForDetailsText = values[0];
        this.machineLinkSuccessText = values[1];
        this.machineLinkErrorText = values[2];
        this.machineUnlinkSuccessText = values[3];
        this.contactUsMessageRequestAvailability = values[4];
        this.contactUsMessageRequestPrice = values[5];
        this.contactUsMessageExpectedDate = values[6];
        this.contactUsMessageRequestOutPhasedItemReplacement = values[7];
      }
    );
    this.isOutPhased = this.productListitem.product.code == 'OUT_PHASED_ITEM';
    this.duplicatePromotionProductSubscription = this.cartService.duplicatePromotionProductSubject.subscribe(event => {
      if(this.productListitem && this.productListitem.promotionProductQuantity && event && this.productListitem.product.code === event.productCode){
        this.productListitem.promotionProductQuantity.cumulativeDuplicateProductQuantity = event.cumulativeDuplicateProductQuantity;
        this.handlePromotionProductQuantityUpdate();
      }
    });
    this.deliverySequenceSubscription = this.sessionService.activeDeliverySequence$.subscribe(f => {
      this.superseededProductSubscription?.unsubscribe();
    });

    this.confirmDeleteButtons = [
      {
        id: 'no',
        resourceKey: 'Global_Dialog_No',
        color: ButtonColors.Secondary
      },
      {
        id: 'yes',
        resourceKey: 'Global_Dialog_Yes',
        color: ButtonColors.Primary
      }
    ];

    this.quantityForm = this.fb.group({
      quantity: [this.productListitem.quantity, [Validators.required, Validators.min(0.05), Validators.max(99999999.999)]],
      isDecimal: [this.isDecimalUnit]
    });

    this.machineLinkForm = this.fb.group({
      equipmentId: this.productListitem.machineLink ? this.productListitem.machineLink.equipmentId : null
    });

    // Animate when an item was added
    if (this.productListitem.isJustAdded === true) {
      this.addedState = 'final';
      setTimeout( () => {
        this.addedState = 'initial';
        setTimeout(() => {
          this.errorMessageResourceKey = null;
          this.productListitem.isJustAdded = false;
        }, 750);
      }, 0);
    }

    this.hasCreateCartPermission = this.frontEndContext.testPermission(allPermissionPolicies.canAddToCart);
    this.refreshUI();
  }
  handlePromotionProductQuantityUpdate() {
    if(this.productListitem.promotionProductQuantity && this.productListitem.promotionProductQuantity.maxOrderableQuantity &&
      ((!this.productListitem.promotionProductQuantity.cumulativeDuplicateProductQuantity && this.productListitem.quantity > this.availablePromotionQuantity) ||
       (this.productListitem.promotionProductQuantity.cumulativeDuplicateProductQuantity > this.availablePromotionQuantity)
      )
    ){
      this.productListitem.showPromotionRestrictionNotice = true;
    }
    else{
      this.productListitem.showPromotionRestrictionNotice = false;
    }
  }
  ngOnDestroy(): void {
    if (this.stopUsingResources) {
      this.stopUsingResources();
    }
    this.responsiveSub?.unsubscribe();
    this.localizationSubscription?.unsubscribe();
    this.superseededProductSubscription?.unsubscribe();
    this.deliverySequenceSubscription?.unsubscribe();
    this.duplicatePromotionProductSubscription?.unsubscribe();
  }

  expandOrVisitItem(item: ProductListItem, index: number) {
    item.isKitItem=true;
    if(item.product.hasKit){
      firstValueFrom(this.kitsApi.getKitProducts({ kitProductId: item.product.id.toString()}))
        .then( (kitproducts) => {
          if (kitproducts) {
            this.relatedkitItems= kitproducts;
          }
        }, () => {
          // Ignore errors.
        });
    }

  }
  collapseCart(item: ProductListItem): void {
    item.isKitItem=true;
  }
  trackByCartId(index: number, item: ProductListItem) {
    return item.product.code;
  }
  deleteDialogButtonClick(dialog, button: DialogButton) {
    if (button.id === 'yes') {
      this.removalPending = true;
      this.isLoading = true;
      firstValueFrom(this.cartsController.deleteCartItem({cartId: this.cartId.toString(), itemId:this.productListitem.itemId.toString()})).then(remainingItemsInCart => {
        if(this.isActiveCart){
          if (remainingItemsInCart > 0) {
            this.broadcast.setCartEvent({refreshActiveCartCount:true});
            this.cartService.calculateSuggestedKits();
            this.cartService.duplicatePromotionProductSubject.next(null);
          } else {
            this.broadcast.setCartEvent({count: 0});
          }
        }
        this.leaveTheStage();
      }).catch(err => {
        this.notifications.showText("Failed to delete item from cart.", NoticeType.Error, false);
        this.log.error("Failed to delete item from cart.", err);
        this.isLoading = false;
        this.errorMessageResourceKey = "fel";
        this.removalPending = false;
      });
    }
    dialog.close();
  }

  toggleMachineLink(): void {
    this.machineLinkOpen = !this.machineLinkOpen;
  }

  updateEquipment(equipment: any[]): void {
    this.equipment = equipment;
    this.log.debug('Machines, update equipment: ', this.equipment);
  }

  toggleKitItems(): void {
    if (!this.showKitItems) {
      this.kitItemsLoading = true;
      firstValueFrom(this.kitsApi.getKitProducts({
        kitProductId: this.productListitem.product.id.toString()
      })).then(result => {
        this.log.debug('Kit item products:', result);
        if (result.length > 0) {
          this.productListitem.kitItems = result.map(r => <KitItem>{
            name : r.name,
            position: r.position,
            quantity: r.quantity
          });
        } else {
          this.productListitem.kitItems = [];
        }
        this.kitItemsLoading = false;
        this.showKitItems = true;
      }).catch(error => {
        this.log.error('Failed to load kit products', error);
        this.productListitem.kitItems = null;
        this.kitItemsLoading = false;
        this.showKitItems = true;
      });
    } else {
      this.kitItemsLoading = false;
      this.showKitItems = !this.showKitItems;
    }
  }

  showUpsellModal(upsellProductId: number) {
    this.cartService.showUpsellModalComponent(upsellProductId);
  }

  showCrossSellModal(crossSellProductIds?: number[]) {
    this.cartService.showCrossSellModalComponent(crossSellProductIds);
  }

  get asideWitdh(): number {
    const priceWidth = !this.hidePrice ? 121 : 0;
    const totalPriceWidth = !this.hideTotalPrice ? 150 : 0;
    const quantityWidth = 94;
    const actionWidth = !this.hideAction ? 70 : 0;
    return priceWidth + totalPriceWidth + quantityWidth + actionWidth;
    // return 300;
  }
  redirectToDocumoto(){
    const documotoDeepUrl = this.productListitem.documotoDeepUrl;
    if(documotoDeepUrl){
      const index = documotoDeepUrl.indexOf('?q=');
      const mediaIdentifier = documotoDeepUrl.substring(0,index);
      const partNumber = documotoDeepUrl.substring(index+3);
      this.partsCatalogueService.navigate({mediaIdentifier: mediaIdentifier, partsNumber: partNumber});
    }
  }
  onClickProductImage(product: ProductListItemProduct)
  {
    const productCode = this.productListitem.superseededByProductCode ? this.productListitem.superseededByProductCode : product.code;
    const url = this.frontEndContext.Navigation.ensureUrlLanguage(`/shop/products/` + productCode);
    this.router.navigateByUrl(url);
  }

  clickedContactUsLinkForOutPhasedItem(): void{
    const productCode = this.productListitem.superseededByProductCode || this.productListitem.product.code;
    this.contactUsService.openContactUs(ContactUsTopic.RequestOutPhasedItemReplacement, this.contactUsMessageRequestOutPhasedItemReplacement.replace('{code}', productCode));
  }

}
