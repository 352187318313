import { CartsApiController } from 'root/mibp-openapi-gen/controllers';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Guid } from "guid-typescript";
import { CartService, ScrollToService, MibpLogger, LogService } from "root/services";
import { firstValueFrom, Subscription } from "rxjs";
import { ProductListComponent, ProductListItem } from "..";
import { ProductListService } from "../../services";
import { CartListItem } from "../cart-list/cart-list";
import { AddItemToCartSource, CartItemViewModelCartItemPagedResponse, PagedOptions, PriceAndAvailabilitySource, ProductExpectedDateRequestDto } from 'root/mibp-openapi-gen/models';


@Component({
  selector: 'mibp-product-list-paginated',
  templateUrl: './product-list-paginated.component.html',
  styleUrls: ['./product-list-paginated.component.scss']
})
export class ProductListPaginatedComponent implements OnInit{
    @Input() cart : CartListItem;
    ecomItems : ProductListItem[];
    @Input() hidePrice: boolean;
    @Input() hideTotalPrice: boolean;
    @Input() hideAvailability: boolean;
    @Input() hideAddToCart = true;
    @Input() hideDelete: boolean;
    @Input() hideQuickAdd = true;
    @Input() skipFocusOnQuickAdd: boolean;
    @Input() hideRelatedButtons: boolean;
    @Input() hideRelatedProducts: boolean;
    @Input() quickAddButtonTextKey: string;
    @Input() priceAndAvailabilitySource: PriceAndAvailabilitySource;
    @Input() addItemToCartSource =  AddItemToCartSource.QuickAdd;
    @Input() disableDuplicate = false;
    @ViewChild(ProductListComponent) cartListComponent: ProductListComponent;
    @ViewChild("cartListElement", { read: ElementRef }) cartListElement: ElementRef;
    itemsRemoved = 0;
    itemsAdded = 0;
    pageSize = 10;
    currentIndex: number;
    totalCount: number;
    cartItemSubscription: Subscription;
    loadedIndex :number;
    isLoading: boolean;
    log: MibpLogger;

    @Output() removedItem = new EventEmitter<string>();
    @Output() addedItem = new EventEmitter<string>();
    constructor(private cartService: CartService, private productListService: ProductListService, private scrollService: ScrollToService, private cartsController: CartsApiController, log: LogService) {
      this.log = log.withPrefix('product-list-paginated');
    }
    ngOnInit(): void {
      this.currentIndex = 0;
      this.loadedIndex = -1;
      this.refreshCartItems(false);
    }
    onDeleteCartItem():void {
      this.itemsRemoved ++;
      this.removedItem.emit(this.cart.id);
      this.refreshCartItems(true);
    }
    onCartAdded():void {
      this.itemsAdded++;
      this.addedItem.emit(this.cart.id);
      this.refreshCartItems(true);
    }
    changePage(a:any):void {
      this.currentIndex = (a.currentPage - 1) * this.pageSize;
      if (a.isUserEvent&&this.cartListElement.nativeElement) {
        this.refreshCartItems(true);
        this.scrollService.scrollToElement(this.cartListElement.nativeElement, () => {

        });
      }

    }

    refreshCartItems(requestPriceAndAvailabilityCall:any):void {
      if (!this.cart) {
        this.ecomItems = null;
        this.totalCount = 0;
        return;
      }
      this.log.warn('getItems + price (refreshCartItems)');
      firstValueFrom(this.cartsController.getItems({cartId:this.cart.id, body:<PagedOptions>{
        take: 10,
        skip: this.currentIndex
      }})).then(cartitemPagedResponse => {
        this.initPageItems(cartitemPagedResponse);
        const products = cartitemPagedResponse.items.map(i => {
          return {
            quantity: i.item.quantity,
            productCode: i.superseededLoadedFromDatabase && i.item.superseededByNewProduct?.code ? i.item.superseededByNewProduct?.code : i.item.newProduct.code
          } as ProductExpectedDateRequestDto;
        });

        if(requestPriceAndAvailabilityCall) {
          this.productListService.getPriceAndAvailabilitiesOfProducts(products, this.ecomItems, this.priceAndAvailabilitySource, this.cart?.id, false);
        }
      }).catch(error =>{
        this.log.error('Error fetching cartitems', error);
      });

    }
    initPageItems(page: CartItemViewModelCartItemPagedResponse):void {
      const productListItems = this.productListService.mapFromCartItems(page.items);

      this.totalCount = page.totalCount;

      if (page !== null && this.itemsAdded > 0 && this.cart) {
        this.itemsAdded --;

        if (this.currentIndex === 0 && this.loadedIndex === this.currentIndex) {
          // Items have been added and we're on page 1. Let's make it look nice.

          productListItems.forEach((newItem, ix) => {
            const existingItemIndex = this.ecomItems
              .findIndex(item => item.itemId.toString() === newItem.itemId.toString());

            if (existingItemIndex === -1) {
              newItem.isJustAdded = true;
              this.ecomItems.splice(ix, 0, newItem);
            }
          });

          // Make sure we only have pagesize items on this page
          if (this.ecomItems.length > this.pageSize) {
            this.ecomItems.splice(this.pageSize );
          }
          this.isLoading = false;
          return;

        } // Not on page 1? Then just replace the whole list
      } else if (this.cart && page !== null && this.itemsRemoved > 0) {

        if (page.totalCount > 0 && productListItems.length === 0 && this.loadedIndex > 0) {
          this.moveToPrevPage();
          this.isLoading = false;
          return;
        }
        this.itemsRemoved--;
        let itemsDeleted = 0;
        if (this.ecomItems) {
          this.ecomItems.forEach((existingItem) => {
            const inNewList = productListItems.findIndex(item => item.itemId.toString() === existingItem.itemId.toString());

            if (inNewList === -1) {
              this.cartListComponent.removeById(existingItem.itemId);
              itemsDeleted++;
            }

          });

        }

        // Make sure we have pageSize items if available
        if (productListItems && this.ecomItems && productListItems.length > (this.ecomItems.length - itemsDeleted)) {
          const itemsToAdd = productListItems.slice(this.pageSize - (this.pageSize - (this.ecomItems.length - itemsDeleted)));
          itemsToAdd.forEach(item => {
            this.ecomItems.push(item);
          });
        }

        if (page.totalCount === 0) {
          this.ecomItems = [];
          this.currentIndex = 0;
          this.loadedIndex = 0;
          this.itemsRemoved = 0;
          this.itemsAdded = 0;
        }

        this.isLoading = false;
        if(!this.ecomItems)
        {
          this.refreshCartItems(true);
        }
        return;
      }
      this.isLoading = false;
      this.ecomItems = productListItems;
      this.loadedIndex = this.currentIndex;
      this.totalCount = page.totalCount;
    }
    moveToPrevPage():void {
      this.currentIndex -= this.pageSize;
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      }
      this.refreshCartItems(true);
    }
}
