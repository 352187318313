import { SupportCaseShipingTrackingData } from 'root/components/contact-dialog/new-contact-us-form/contact-us-form-shipping-tracking/contact-us-shipping-tracking-form.component';
import { SupportCaseItemEnquiryData } from "./contact-us-form-item-enquiry/contact-us-item-enquiry-form.component";
import { SupportCaseGeneralData } from './contact-us-form-general/contact-us-general-form.component';
import { ProductUpgradeRequestQuoteData } from './contact-us-request-quote/contact-us-request-quote-form.component';

export interface SupportCaseDialogInit {
  open: boolean;
  formData?: SupportCaseFormData;
}

export interface SupportCaseFormData {
  newContactUsTopic: NewContactUsTopic;
  itemEnquiryData : SupportCaseItemEnquiryData;
  shipingTrackingData: SupportCaseShipingTrackingData;
  GeneralData: SupportCaseGeneralData;
  upgradeQuoteData : ProductUpgradeRequestQuoteData;
}

export enum NewContactUsTopic {
  GeneralQuestionOrRequest = 'General question or request',
  ItemEnquiry = 'Item enquiry',
  PageMissing = 'Page missing',
  ReturnsAndOrderDiscrepancies = 'Returns and order discrepancies',
  ShippingAndTracking = 'Shipping and tracking',
  TechnicalRequest = 'Technical request',
  TradingAccountQueryOrIssue = 'Trading account query or issue',
  WebsiteFeedback = 'Website feedback',
  RequestUpgradeQuote = 'Request quote for machine model upgrade'
}
