<div class="main">
  <!-- Toolbar -->
  <div class="toolbar" *ngIf="!isLoading && !error">
    <button class="toolbar__control" [class.toolbar__control--disabled]="!hasOutline"
      (click)="toggleOutline()" [mibpResourceStringAttributes]="{ title: 'PdfViewer_Toolbar_BookmarksTooltip' }">
      <span translate="no" class="material-icon material-icon--large material-icon--strong">format_list_numbered</span>
    </button>
    <div class="toolbar__control-container">
      <span><mibp-resource-string key="PdfViewer_Toolbar_SelectPageStartText"></mibp-resource-string></span>
      <button class="toolbar__control" (click)="changePage(-1)">
        <span translate="no" class="material-icon material-icon--large material-icon--strong">chevron_left</span>
      </button>

      <input type="number" min="1" [max]="numberOfPages" [ngModel]="currentPageNumber"
        (ngModelChange)="onPageNumberModelChanged($event)" />

      <button class="toolbar__control" (click)="changePage(1)">
        <span translate="no" class="material-icon material-icon--large material-icon--strong">chevron_right</span>
      </button>
      <span><mibp-resource-string key="PdfViewer_Toolbar_SelectPageOfTotalText" [macros]="{numberOfPages: numberOfPages}"></mibp-resource-string></span>
    </div>

    <button class="toolbar__control" (click)="changeZoom(0.1)" [mibpResourceStringAttributes]="{ title: 'PdfViewer_Toolbar_ZoomInTooltip' }">
      <span translate="no" class="material-icon material-icon--large material-icon--strong">zoom_in</span>
    </button>

    <button class="toolbar__control" (click)="changeZoom(-0.1)" [mibpResourceStringAttributes]="{ title: 'PdfViewer_Toolbar_ZoomOutTooltip' }">
      <span translate="no" class="material-icon material-icon--large material-icon--strong">zoom_out</span>
    </button>

    <button class="toolbar__control" (click)="setZoomScale('page-width')" [mibpResourceStringAttributes]="{ title: 'PdfViewer_Toolbar_FitToWidthTooltip' }">
      <span translate="no" class="material-icon material-icon--large material-icon--strong"
        [class.selected]="zoomScale == 'page-width'">width_full</span>
    </button>

    <button class="toolbar__control" (click)="setZoomScale('page-height')" [mibpResourceStringAttributes]="{ title: 'PdfViewer_Toolbar_FitToHeightTooltip' }">
      <span translate="no" class="material-icon material-icon--large material-icon--strong"
        [class.selected]="zoomScale == 'page-height'">width_normal</span>
    </button>

    <button class="toolbar__control" (click)="setZoomScale('page-fit')" [mibpResourceStringAttributes]="{ title: 'PdfViewer_Toolbar_FitToPageTooltip' }">
      <span translate="no" class="material-icon material-icon--large material-icon--strong"
        [class.selected]="zoomScale == 'page-fit'">fit_screen</span>
    </button>

    <div *ngIf="filename" class="toolbar__control-separator-box toolbar__control-separator-box--border-left">
      <button class="toolbar__control" (click)="download()" [mibpResourceStringAttributes]="{ title: 'PdfViewer_Toolbar_DownloadPDFTooltip' }">
        <span translate="no" class="material-icon material-icon--large material-icon--strong">
          download</span>
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="content" [class.content--hidden]="isLoading || error">
    <div *ngIf="showOutline" class="bookmarks">
      <h3 class="my-header bookmarks__title">
        <mibp-resource-string key="PdfViewer_Bookmarks_Title"></mibp-resource-string>
      </h3>
      <ng-container [ngTemplateOutlet]="treeTemplate"
        [ngTemplateOutletContext]="{nodes: outline, depth: 0, parentexpanded: false}"></ng-container>
      <ng-template let-nodes="nodes" let-depth="depth" let-parentexpanded="parentexpanded" #treeTemplate>
        <ul class="nodes" [style.marginLeft]="depth > 0 ? '20px' : null ">
          <li *ngFor="let node of nodes" class="bookmarks__node" [attr.aria-expanded]="node.isExpanded ? true : false"
            [class.bookmarks__node--hidden]="depth > 0 && !parentexpanded"
            [style.marginLeft]="depth > 0 && node.childNodes?.length > 0 ? '-29px' : null ">
            <a href="javascript:void(0)" *ngIf="node.childNodes?.length > 0" (click)="toggleNode(node)"
              class="bookmarks__node--expand-collapse">
              <span translate="no" class="material-icon material-icon--medium material-icon--strong">{{ node.isExpanded
                ? 'keyboard_arrow_down' : 'chevron_right' }}</span></a>
            <div>
              <a href="javascript:void(0)" (click)="navigateTo(node)" [class.bold]="depth == 0">{{node.title}}</a>
              <ng-template [ngIf]="node.childNodes.length > 0">
                <ng-container [ngTemplateOutlet]="treeTemplate"
                  [ngTemplateOutletContext]="{nodes: node.childNodes, depth: depth +1, parentexpanded: node.isExpanded}"></ng-container>
              </ng-template>
            </div>
          </li>
        </ul>
      </ng-template>
    </div>
    <div class="document" #pdfDocumentContainer>
      <pdf-viewer [src]="url" [(page)]="currentPageNumber" [fit-to-page]="fitToPage" [render-text]="true"
        (after-load-complete)="afterLoadComplete($event)" [original-size]="false" [autoresize]="autoresize"
        [zoom]="zoom" [zoom-scale]="zoomScale" [show-all]="showAllPages" [stick-to-page]="false"
        (pageChange)="pageChange($event)" (on-progress)="onProgress($event)" (error)="onError($event)"></pdf-viewer>
    </div>
  </div>

  <div class="loader" *ngIf="isLoading && !error">
    <div class="loader__progress-box">
      <p>
        <mibp-resource-string key="General_LoadingPdf"></mibp-resource-string>
      </p>        
      <div class="loader__progress-bar" *ngIf="fileSize">
        <div [style.width]="pdfLoadPercent + '%'"></div>
      </div>
      <mibp-loader type='spinner'></mibp-loader><span *ngIf="fileSize">&nbsp;{{pdfLoadPercent}}%</span>
    </div>
  </div>

  <div class="error" *ngIf="!isLoading && error">
    <div>
      <p><mibp-resource-string key="PdfViewer_LoadError_Message"></mibp-resource-string></p>
    </div>    
  </div>
</div>