import { Subscription } from 'rxjs/internal/Subscription';

import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DropdownArgs, DropdownComponent, DropdownData, DropdownInput } from '../dropdown';
import { Observable } from 'rxjs';
import { MachineFilterRequest, MachineFilterType } from 'root/mibp-openapi-gen/models';
import { MachineFilterService } from 'root/services/machine-filter/machine-filter.service';
import { Params, Router } from '@angular/router';
import { FrontendContextService, ScrollToService, SignalR_DeliverySequence } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';

export interface MachineFilterSelection {
  machine:string[];
  model:string[];
  subgroup:string[];
  group:string[];
}

@Component({
  selector: 'mibp-machine-filter',
  templateUrl: './machine-filter.component.html',
  styleUrls: ['./machine-filter.component.scss']
})
export class MachineFilterComponent implements OnInit, OnDestroy {

  @Input() currentPage: 'machines' | 'kits' | 'locations' | 'bulletins' |'partsmanuals' | 'promotions'| 'electronicmanuals';
  @Input() extraQueryParams: Params;
  @Input() isHorizontal = false;
  @Input() promotionId: number;
  @Input() doNotScrollToTop = false;
  @Input() productCode: string;

  machineFilterEnabled = true;
  machineGroupFilterEnabled = true;
  machineSubGroupFilterEnabled = true;
  machineModelFilterEnabled = true;
  dropdownPageSize = 10;
  @ViewChild(DropdownComponent) machineDropdown: DropdownComponent;
  @ViewChild(DropdownComponent) machineSubGroupDropdown: DropdownComponent;
  @ViewChild(DropdownComponent) machineGroupDropdown: DropdownComponent;
  @ViewChild(DropdownComponent) machineModelDropdown: DropdownComponent;
  machines$: Observable<DropdownData>;
  subgroups$: Observable<DropdownData>;
  groups$: Observable<DropdownData>;
  models$: Observable<DropdownData>;
  initialMachineSelection: DropdownInput[];
  initialModelSelection: DropdownInput[];
  initialGroupSelection: DropdownInput[];
  initialSubgroupSelection: DropdownInput[];
  deliverySequenceSubscription: Subscription;
  selectedFilters: MachineFilterSelection = {
    machine : [],
    model : [],
    group : [],
    subgroup : [],
  };
  machineFilterType = MachineFilterType.Machine;
  modelFilterType = MachineFilterType.Model;
  subgroupFilterType = MachineFilterType.SubGroup;
  groupFilterType = MachineFilterType.Group;
  @Output() filterChange = new EventEmitter<MachineFilterSelection>();
  constructor(private machineservice: MachineFilterService, private router: Router, private sessionService: MibpSessionService, private scrollService: ScrollToService) {
  }
  ngOnDestroy(): void {
    this.deliverySequenceSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.setIntitialValues();
    this.intitializedropdowns();
    this.deliverySequenceSubscription = this.sessionService.activeDeliverySequence$.subscribe(() => {
      if(this.deliverySequenceSubscription){
        this.resetFilters();
        this.resetDropdowns();
      }
    });
  }

  public clearFilters(doNotNavigate = false): void {
    this.resetFilters();
    this.resetDropdowns(doNotNavigate);
    this.selectedFilters = {
      machine : [],
      model : [],
      group : [],
      subgroup : [],
    };
  }

  resetFilters(){
    this.machines$ = null;
    this.models$ = null;
    this.subgroups$ = null;
    this.groups$ = null;
  }

  resetDropdowns(doNotNavigate = false) {
    this.selectedFilters.machine = [];
    this.selectedFilters.model = [];
    this.selectedFilters.subgroup = [];
    this.selectedFilters.group = [];
    this.setDropdownValues();
    if (!doNotNavigate) {
      this.routeUpdate();
    }
  }

  setIntitialValues(){
    const intitialFilter = this.machineservice.getInitialMachineFiltersFromRoute();
    if(intitialFilter){
      this.selectedFilters = intitialFilter;
      this.setDropdownValues();
    }
  }

  setDropdownValues () {
    this.initialMachineSelection = this.selectedFilters.machine.map(value=><DropdownInput>{text:value, value:value});
    this.initialModelSelection = this.selectedFilters.model.map(value=><DropdownInput>{text:value, value:value});
    this.initialSubgroupSelection = this.selectedFilters.subgroup.map(value=><DropdownInput>{text:value, value:value});
    this.initialGroupSelection = this.selectedFilters.group.map(value=><DropdownInput>{text:value, value:value});
  }

  filtermachines(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.Machine, query: a.query,
      productModels: this.selectedFilters.model,
      productGroups: this.selectedFilters.group,
      productSubGroups: this.selectedFilters.subgroup,
      machines:[],
    productCode: this.productCode};
    switch (this.currentPage) {
    case 'kits': {
      this.machines$ = this.machineservice.getFiltersForKits(filterRequest);
      break;
    }
    case 'locations': {
      this.machines$ = this.machineservice.getFiltersForLocations(filterRequest);
      break;
    }
    case 'bulletins': {
      this.machines$ = this.machineservice.getFiltersForBulletins(filterRequest);
      break;

    }
    case 'partsmanuals': {
      this.machines$ = this.machineservice.getFiltersForPartsManuals(filterRequest);
      break;
    }
    case 'electronicmanuals': {
      this.machines$ = this.machineservice.getFiltersForElectronicManuals(filterRequest);
      break;
    }
    case 'promotions': {
      this.machines$ = this.machineservice.getFiltersForPromotions(filterRequest, this.promotionId);
      break;
    }
    }

  }
  filterModels(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.Model, query: a.query,
      productModels: [], productGroups: this.selectedFilters.group,
      productSubGroups: this.selectedFilters.subgroup,
      machines: this.selectedFilters.machine,
      productCode: this.productCode};
    switch (this.currentPage) {
    case 'machines': {
      this.models$ = this.machineservice.getFiltersForEquipment(filterRequest);
      break;
    }
    case 'kits': {
      this.models$ = this.machineservice.getFiltersForKits(filterRequest);
      break;
    }
    case 'locations': {
      this.models$ = this.machineservice.getFiltersForLocations(filterRequest);
      break;
    }
    case 'bulletins': {
      this.models$ = this.machineservice.getFiltersForBulletins(filterRequest);
      break;
    }
    case 'partsmanuals': {
      this.models$ = this.machineservice.getFiltersForPartsManuals(filterRequest);
      break;
    }
    case 'promotions': {
      this.models$ = this.machineservice.getFiltersForPromotions(filterRequest, this.promotionId);
      break;
    }
    case 'electronicmanuals': {
      this.models$ = this.machineservice.getFiltersForElectronicManuals(filterRequest);
      break;
    }
    }

  }

  filterSubGroups(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.SubGroup, query: a.query,
      productModels: this.selectedFilters.model,
      productGroups: this.selectedFilters.group, productSubGroups: [],
      machines: this.selectedFilters.machine,
      productCode: this.productCode};
    switch (this.currentPage) {
    case 'machines': {
      this.subgroups$ = this.machineservice.getFiltersForEquipment(filterRequest);
      break;
    }
    case 'locations': {
      this.subgroups$ = this.machineservice.getFiltersForLocations(filterRequest);
      break;
    }
    case 'bulletins': {
      this.subgroups$ = this.machineservice.getFiltersForBulletins(filterRequest);
      break;
    }
    case 'partsmanuals': {
      this.subgroups$ = this.machineservice.getFiltersForPartsManuals(filterRequest);
      break;
    }
    case 'promotions': {
      this.subgroups$ = this.machineservice.getFiltersForPromotions(filterRequest, this.promotionId);
      break;
    }
    case 'electronicmanuals': {
      this.models$ = this.machineservice.getFiltersForElectronicManuals(filterRequest);
      break;
    }
    }
  }

  filterGroups(a: DropdownArgs): void {
    const filterRequest = <MachineFilterRequest>{ take: a.take, skip: a.index,
      filterType: MachineFilterType.Group, query: a.query,
      productModels: this.selectedFilters.model, productGroups: [],
      productSubGroups: this.selectedFilters.subgroup,
      machines: this.selectedFilters.machine,
      productCode: this.productCode};
    switch (this.currentPage) {
    case 'machines': {
      this.groups$ = this.machineservice.getFiltersForEquipment(filterRequest);
      break;
    }
    case 'locations': {
      this.groups$ = this.machineservice.getFiltersForLocations(filterRequest);
      break;
    }
    case 'bulletins': {
      this.groups$ = this.machineservice.getFiltersForBulletins(filterRequest);
      break;
    }
    case 'partsmanuals': {
      this.groups$ = this.machineservice.getFiltersForPartsManuals(filterRequest);
      break;
    }
    case 'promotions': {
      this.groups$ = this.machineservice.getFiltersForPromotions(filterRequest, this.promotionId);
      break;
    }
    case 'electronicmanuals': {
      this.groups$ = this.machineservice.getFiltersForElectronicManuals(filterRequest);
      break;
    }
    }
  }

  onDropdownValueChange(e: DropdownInput[], type: MachineFilterType){
    switch (type) {
    case MachineFilterType.Machine: {
      this.handelMachineDropdownChange(e);
      break;
    }
    case MachineFilterType.Model: {
      this.handelModelDropdownChange(e);
      break;
    }
    case MachineFilterType.SubGroup: {
      this.handelSubGroupDropdownChange(e);
      break;
    }
    case MachineFilterType.Group: {
      this.handelGroupDropdownChange(e);
      break;
    }
    }
  }

  handelMachineDropdownChange(e: DropdownInput[]){
    this.models$ = null;
    this.subgroups$ = null;
    this.groups$ = null;
    this.selectedFilters.machine = e.map(dropdwonvalue => dropdwonvalue.value);
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }
  routeUpdate(){
    const filterQueryParams = this.createFilterQueryString();
    let params = { machinefilter: filterQueryParams};
    setTimeout(() => {
      if (this.extraQueryParams) {
        params = Object.assign({}, params, this.extraQueryParams);
      }

      if (this.doNotScrollToTop) {
        this.scrollService.stopNextScrollToTop();
      }
      this.router.navigate([], { queryParams: params, queryParamsHandling: 'merge' });
    });
  }
  createFilterQueryString(){
    const querystringFilter:{[key: string]: any } = {};
    if(this.selectedFilters.machine && this.selectedFilters.machine.length > 0){
      querystringFilter['machine'] = this.selectedFilters.machine;
    }
    if(this.selectedFilters.group && this.selectedFilters.group.length > 0){
      querystringFilter['group'] = this.selectedFilters.group;
    }
    if(this.selectedFilters.model && this.selectedFilters.model.length > 0){
      querystringFilter['model'] = this.selectedFilters.model;
    }
    if(this.selectedFilters.subgroup && this.selectedFilters.subgroup.length > 0){
      querystringFilter['subgroup'] = this.selectedFilters.subgroup;
    }
    return Object.keys(querystringFilter).length > 0 ? JSON.stringify(querystringFilter) : null;
  }

  handelModelDropdownChange(e: DropdownInput[]){
    this.machines$ = null;
    this.subgroups$ = null;
    this.groups$ = null;
    this.selectedFilters.model = e.map(dropdwonvalue => dropdwonvalue.value);
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  handelSubGroupDropdownChange(e: DropdownInput[]){
    this.models$ = null;
    this.machines$ = null;
    this.groups$ = null;
    this.selectedFilters.subgroup = e.map(dropdwonvalue => dropdwonvalue.value);
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  handelGroupDropdownChange(e: DropdownInput[]){
    this.models$ = null;
    this.subgroups$ = null;
    this.machines$ = null;
    this.selectedFilters.group = e.map(dropdwonvalue => dropdwonvalue.value);
    this.filterChange.emit(this.selectedFilters);
    this.routeUpdate();
  }

  intitializedropdowns(){
    switch (this.currentPage) {
    case 'machines': {
      this.machineFilterEnabled = false;
      break;
    }
    case 'kits': {
      this.machineSubGroupFilterEnabled = false;
      this.machineGroupFilterEnabled = false;
      break;
    }
    case 'electronicmanuals': {
      this.machineSubGroupFilterEnabled = false;
      break;
    }
    case 'locations': {
      //statements;
      break;
    }
    case 'bulletins': {
      //statements;
      break;
    }
    case 'partsmanuals': {
      //statements;
      break;
    }
    case 'promotions': {
      //statements;
      break;
    }
    }
  }
}
