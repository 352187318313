import { Injectable } from '@angular/core';
import { MediaNavigationFolderVm } from 'root/mibp-openapi-gen/models';

@Injectable()
export class PartsManualsService {

  /**
   * Prepare the folder data returned from backend
   */
  public prepareFolders(folders: MediaNavigationFolderVm[]): void {
    if (folders && folders.length > 0) {
      this.clearAssemblyNumberAndRevisionIfEqualToName(folders);
    }
  }

  /**
   * If name is equal to [assemblynumber]-[revision] then assemblynumber and revision
   * values are cleared to avoid duplicate information since we output them all in the UI
   */
  private clearAssemblyNumberAndRevisionIfEqualToName(folders: MediaNavigationFolderVm[]): void {
    for (const folder of folders) {
      // If folder name equals the asm number + revision then we dont' want to show duplicate values for the user
      if (folder.name == `${folder.assemblyNumber}-${folder.revision}` ) {
        folder.assemblyNumber = null;
        folder.revision = null;
      }

      // Continue to traverse down the tree if folder have children
      if (folder.childFolders?.length > 0) {
        this.clearAssemblyNumberAndRevisionIfEqualToName(folder.childFolders);
      }
    }
  }


}
