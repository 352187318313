/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ComponentTypePagedResponse } from '../models/component-type-paged-response';
import { EquipmentDetailsForReturnVm } from '../models/equipment-details-for-return-vm';
import { EquipmentDtoResponse } from '../models/equipment-dto-response';
import { EquipmentIndexDtoSearchResponse } from '../models/equipment-index-dto-search-response';
import { EquipmentSearchResponse } from '../models/equipment-search-response';
import { EquipmentSignalDto } from '../models/equipment-signal-dto';
import { EquipmentSignalVm } from '../models/equipment-signal-vm';
import { FleetCountResponse } from '../models/fleet-count-response';
import { getEngineHoursIfEquipmentHasKnowledgeBox } from '../fn/equipments/get-engine-hours-if-equipment-has-knowledge-box';
import { GetEngineHoursIfEquipmentHasKnowledgeBox$Params } from '../fn/equipments/get-engine-hours-if-equipment-has-knowledge-box';
import { getEngineHoursIfEquipmentHasKnowledgeBox$Plain } from '../fn/equipments/get-engine-hours-if-equipment-has-knowledge-box-plain';
import { GetEngineHoursIfEquipmentHasKnowledgeBox$Plain$Params } from '../fn/equipments/get-engine-hours-if-equipment-has-knowledge-box-plain';
import { getEquipmentComponentTypes } from '../fn/equipments/get-equipment-component-types';
import { GetEquipmentComponentTypes$Params } from '../fn/equipments/get-equipment-component-types';
import { getEquipmentComponentTypes$Plain } from '../fn/equipments/get-equipment-component-types-plain';
import { GetEquipmentComponentTypes$Plain$Params } from '../fn/equipments/get-equipment-component-types-plain';
import { getEquipmentDetails } from '../fn/equipments/get-equipment-details';
import { GetEquipmentDetails$Params } from '../fn/equipments/get-equipment-details';
import { getEquipmentDetails$Plain } from '../fn/equipments/get-equipment-details-plain';
import { GetEquipmentDetails$Plain$Params } from '../fn/equipments/get-equipment-details-plain';
import { getEquipmentFleetCount } from '../fn/equipments/get-equipment-fleet-count';
import { GetEquipmentFleetCount$Params } from '../fn/equipments/get-equipment-fleet-count';
import { getEquipmentFleetCount$Plain } from '../fn/equipments/get-equipment-fleet-count-plain';
import { GetEquipmentFleetCount$Plain$Params } from '../fn/equipments/get-equipment-fleet-count-plain';
import { getEquipmentsByKit } from '../fn/equipments/get-equipments-by-kit';
import { GetEquipmentsByKit$Params } from '../fn/equipments/get-equipments-by-kit';
import { getEquipmentsByKit$Plain } from '../fn/equipments/get-equipments-by-kit-plain';
import { GetEquipmentsByKit$Plain$Params } from '../fn/equipments/get-equipments-by-kit-plain';
import { getEquipmentsByProductid } from '../fn/equipments/get-equipments-by-productid';
import { GetEquipmentsByProductid$Params } from '../fn/equipments/get-equipments-by-productid';
import { getEquipmentsByProductid$Plain } from '../fn/equipments/get-equipments-by-productid-plain';
import { GetEquipmentsByProductid$Plain$Params } from '../fn/equipments/get-equipments-by-productid-plain';
import { getEquipmentsByProductSearchFilter } from '../fn/equipments/get-equipments-by-product-search-filter';
import { GetEquipmentsByProductSearchFilter$Params } from '../fn/equipments/get-equipments-by-product-search-filter';
import { getEquipmentsByProductSearchFilter$Plain } from '../fn/equipments/get-equipments-by-product-search-filter-plain';
import { GetEquipmentsByProductSearchFilter$Plain$Params } from '../fn/equipments/get-equipments-by-product-search-filter-plain';
import { getEquipmentsEngineHoursStatus } from '../fn/equipments/get-equipments-engine-hours-status';
import { GetEquipmentsEngineHoursStatus$Params } from '../fn/equipments/get-equipments-engine-hours-status';
import { getEquipmentsEngineHoursStatus$Plain } from '../fn/equipments/get-equipments-engine-hours-status-plain';
import { GetEquipmentsEngineHoursStatus$Plain$Params } from '../fn/equipments/get-equipments-engine-hours-status-plain';
import { getEquipmentSignals } from '../fn/equipments/get-equipment-signals';
import { GetEquipmentSignals$Params } from '../fn/equipments/get-equipment-signals';
import { getEquipmentSignals$Plain } from '../fn/equipments/get-equipment-signals-plain';
import { GetEquipmentSignals$Plain$Params } from '../fn/equipments/get-equipment-signals-plain';
import { getMachineFilterForBulletin } from '../fn/equipments/get-machine-filter-for-bulletin';
import { GetMachineFilterForBulletin$Params } from '../fn/equipments/get-machine-filter-for-bulletin';
import { getMachineFilterForBulletin$Plain } from '../fn/equipments/get-machine-filter-for-bulletin-plain';
import { GetMachineFilterForBulletin$Plain$Params } from '../fn/equipments/get-machine-filter-for-bulletin-plain';
import { getMachineFilterForElectronicManuals } from '../fn/equipments/get-machine-filter-for-electronic-manuals';
import { GetMachineFilterForElectronicManuals$Params } from '../fn/equipments/get-machine-filter-for-electronic-manuals';
import { getMachineFilterForElectronicManuals$Plain } from '../fn/equipments/get-machine-filter-for-electronic-manuals-plain';
import { GetMachineFilterForElectronicManuals$Plain$Params } from '../fn/equipments/get-machine-filter-for-electronic-manuals-plain';
import { getMachineFilterForEquipments } from '../fn/equipments/get-machine-filter-for-equipments';
import { GetMachineFilterForEquipments$Params } from '../fn/equipments/get-machine-filter-for-equipments';
import { getMachineFilterForEquipments$Plain } from '../fn/equipments/get-machine-filter-for-equipments-plain';
import { GetMachineFilterForEquipments$Plain$Params } from '../fn/equipments/get-machine-filter-for-equipments-plain';
import { getMachineFilterForKit } from '../fn/equipments/get-machine-filter-for-kit';
import { GetMachineFilterForKit$Params } from '../fn/equipments/get-machine-filter-for-kit';
import { getMachineFilterForKit$Plain } from '../fn/equipments/get-machine-filter-for-kit-plain';
import { GetMachineFilterForKit$Plain$Params } from '../fn/equipments/get-machine-filter-for-kit-plain';
import { getMachineFilterForLocation } from '../fn/equipments/get-machine-filter-for-location';
import { GetMachineFilterForLocation$Params } from '../fn/equipments/get-machine-filter-for-location';
import { getMachineFilterForLocation$Plain } from '../fn/equipments/get-machine-filter-for-location-plain';
import { GetMachineFilterForLocation$Plain$Params } from '../fn/equipments/get-machine-filter-for-location-plain';
import { getMachineFilterForPartsManuals } from '../fn/equipments/get-machine-filter-for-parts-manuals';
import { GetMachineFilterForPartsManuals$Params } from '../fn/equipments/get-machine-filter-for-parts-manuals';
import { getMachineFilterForPartsManuals$Plain } from '../fn/equipments/get-machine-filter-for-parts-manuals-plain';
import { GetMachineFilterForPartsManuals$Plain$Params } from '../fn/equipments/get-machine-filter-for-parts-manuals-plain';
import { getMachineFilterForPromotions } from '../fn/equipments/get-machine-filter-for-promotions';
import { GetMachineFilterForPromotions$Params } from '../fn/equipments/get-machine-filter-for-promotions';
import { getMachineFilterForPromotions$Plain } from '../fn/equipments/get-machine-filter-for-promotions-plain';
import { GetMachineFilterForPromotions$Plain$Params } from '../fn/equipments/get-machine-filter-for-promotions-plain';
import { getMachineFilterForWarranty } from '../fn/equipments/get-machine-filter-for-warranty';
import { GetMachineFilterForWarranty$Params } from '../fn/equipments/get-machine-filter-for-warranty';
import { getMachineFilterForWarranty$Plain } from '../fn/equipments/get-machine-filter-for-warranty-plain';
import { GetMachineFilterForWarranty$Plain$Params } from '../fn/equipments/get-machine-filter-for-warranty-plain';
import { getManualAddedEquipmentSignals } from '../fn/equipments/get-manual-added-equipment-signals';
import { GetManualAddedEquipmentSignals$Params } from '../fn/equipments/get-manual-added-equipment-signals';
import { getManualAddedEquipmentSignals$Plain } from '../fn/equipments/get-manual-added-equipment-signals-plain';
import { GetManualAddedEquipmentSignals$Plain$Params } from '../fn/equipments/get-manual-added-equipment-signals-plain';
import { getTopEquipmentsEngineHours } from '../fn/equipments/get-top-equipments-engine-hours';
import { GetTopEquipmentsEngineHours$Params } from '../fn/equipments/get-top-equipments-engine-hours';
import { getTopEquipmentsEngineHours$Plain } from '../fn/equipments/get-top-equipments-engine-hours-plain';
import { GetTopEquipmentsEngineHours$Plain$Params } from '../fn/equipments/get-top-equipments-engine-hours-plain';
import { gridSearchForOrganization } from '../fn/equipments/grid-search-for-organization';
import { GridSearchForOrganization$Params } from '../fn/equipments/grid-search-for-organization';
import { gridSearchForOrganization$Plain } from '../fn/equipments/grid-search-for-organization-plain';
import { GridSearchForOrganization$Plain$Params } from '../fn/equipments/grid-search-for-organization-plain';
import { listEquipment } from '../fn/equipments/list-equipment';
import { ListEquipment$Params } from '../fn/equipments/list-equipment';
import { listEquipment$Plain } from '../fn/equipments/list-equipment-plain';
import { ListEquipment$Plain$Params } from '../fn/equipments/list-equipment-plain';
import { listEquipmentProductAreas } from '../fn/equipments/list-equipment-product-areas';
import { ListEquipmentProductAreas$Params } from '../fn/equipments/list-equipment-product-areas';
import { listEquipmentProductAreas$Plain } from '../fn/equipments/list-equipment-product-areas-plain';
import { ListEquipmentProductAreas$Plain$Params } from '../fn/equipments/list-equipment-product-areas-plain';
import { listEquipmentProductGroups } from '../fn/equipments/list-equipment-product-groups';
import { ListEquipmentProductGroups$Params } from '../fn/equipments/list-equipment-product-groups';
import { listEquipmentProductGroups$Plain } from '../fn/equipments/list-equipment-product-groups-plain';
import { ListEquipmentProductGroups$Plain$Params } from '../fn/equipments/list-equipment-product-groups-plain';
import { listEquipmentProductModels } from '../fn/equipments/list-equipment-product-models';
import { ListEquipmentProductModels$Params } from '../fn/equipments/list-equipment-product-models';
import { listEquipmentProductModels$Plain } from '../fn/equipments/list-equipment-product-models-plain';
import { ListEquipmentProductModels$Plain$Params } from '../fn/equipments/list-equipment-product-models-plain';
import { listEquipmentProductSubGroups } from '../fn/equipments/list-equipment-product-sub-groups';
import { ListEquipmentProductSubGroups$Params } from '../fn/equipments/list-equipment-product-sub-groups';
import { listEquipmentProductSubGroups$Plain } from '../fn/equipments/list-equipment-product-sub-groups-plain';
import { ListEquipmentProductSubGroups$Plain$Params } from '../fn/equipments/list-equipment-product-sub-groups-plain';
import { listEquipmentRemoteSupportProductModels } from '../fn/equipments/list-equipment-remote-support-product-models';
import { ListEquipmentRemoteSupportProductModels$Params } from '../fn/equipments/list-equipment-remote-support-product-models';
import { listEquipmentRemoteSupportProductModels$Plain } from '../fn/equipments/list-equipment-remote-support-product-models-plain';
import { ListEquipmentRemoteSupportProductModels$Plain$Params } from '../fn/equipments/list-equipment-remote-support-product-models-plain';
import { MachineEngineHourCountResponseVm } from '../models/machine-engine-hour-count-response-vm';
import { MachineFilterResponse } from '../models/machine-filter-response';
import { MachineFilterWarrantyResponse } from '../models/machine-filter-warranty-response';
import { MapDtoSearchResponse } from '../models/map-dto-search-response';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { NewProductSearchEquipmentFilterDto } from '../models/new-product-search-equipment-filter-dto';
import { ProductAreaSearchResponse } from '../models/product-area-search-response';
import { ProductGroupSearchResponse } from '../models/product-group-search-response';
import { ProductModelSearchResponse } from '../models/product-model-search-response';
import { ProductSubGroupSearchResponse } from '../models/product-sub-group-search-response';
import { reScopeAllEquipment } from '../fn/equipments/re-scope-all-equipment';
import { ReScopeAllEquipment$Params } from '../fn/equipments/re-scope-all-equipment';
import { reScopeAllEquipment$Plain } from '../fn/equipments/re-scope-all-equipment-plain';
import { ReScopeAllEquipment$Plain$Params } from '../fn/equipments/re-scope-all-equipment-plain';
import { reScopeEquipmentAllProductModels } from '../fn/equipments/re-scope-equipment-all-product-models';
import { ReScopeEquipmentAllProductModels$Params } from '../fn/equipments/re-scope-equipment-all-product-models';
import { reScopeEquipmentAllProductModels$Plain } from '../fn/equipments/re-scope-equipment-all-product-models-plain';
import { ReScopeEquipmentAllProductModels$Plain$Params } from '../fn/equipments/re-scope-equipment-all-product-models-plain';
import { reScopeEquipmentByCompanyBusinessRelation } from '../fn/equipments/re-scope-equipment-by-company-business-relation';
import { ReScopeEquipmentByCompanyBusinessRelation$Params } from '../fn/equipments/re-scope-equipment-by-company-business-relation';
import { reScopeEquipmentByCompanyBusinessRelation$Plain } from '../fn/equipments/re-scope-equipment-by-company-business-relation-plain';
import { ReScopeEquipmentByCompanyBusinessRelation$Plain$Params } from '../fn/equipments/re-scope-equipment-by-company-business-relation-plain';
import { reScopeEquipmentByProduct } from '../fn/equipments/re-scope-equipment-by-product';
import { ReScopeEquipmentByProduct$Params } from '../fn/equipments/re-scope-equipment-by-product';
import { reScopeEquipmentByProduct$Plain } from '../fn/equipments/re-scope-equipment-by-product-plain';
import { ReScopeEquipmentByProduct$Plain$Params } from '../fn/equipments/re-scope-equipment-by-product-plain';
import { searchEquipment } from '../fn/equipments/search-equipment';
import { SearchEquipment$Params } from '../fn/equipments/search-equipment';
import { searchEquipment$Plain } from '../fn/equipments/search-equipment-plain';
import { SearchEquipment$Plain$Params } from '../fn/equipments/search-equipment-plain';
import { searchEquipmentwithDeliverySequence } from '../fn/equipments/search-equipmentwith-delivery-sequence';
import { SearchEquipmentwithDeliverySequence$Params } from '../fn/equipments/search-equipmentwith-delivery-sequence';
import { searchEquipmentwithDeliverySequence$Plain } from '../fn/equipments/search-equipmentwith-delivery-sequence-plain';
import { SearchEquipmentwithDeliverySequence$Plain$Params } from '../fn/equipments/search-equipmentwith-delivery-sequence-plain';
import { searchOperationSites } from '../fn/equipments/search-operation-sites';
import { SearchOperationSites$Params } from '../fn/equipments/search-operation-sites';
import { searchOperationSites$Plain } from '../fn/equipments/search-operation-sites-plain';
import { SearchOperationSites$Plain$Params } from '../fn/equipments/search-operation-sites-plain';
import { sendMachineUpdateRequests } from '../fn/equipments/send-machine-update-requests';
import { SendMachineUpdateRequests$Params } from '../fn/equipments/send-machine-update-requests';
import { updateEquipmentAlias } from '../fn/equipments/update-equipment-alias';
import { UpdateEquipmentAlias$Params } from '../fn/equipments/update-equipment-alias';
import { updateMachineEngineHours } from '../fn/equipments/update-machine-engine-hours';
import { UpdateMachineEngineHours$Params } from '../fn/equipments/update-machine-engine-hours';
import { updateMachineEngineHours$Plain } from '../fn/equipments/update-machine-engine-hours-plain';
import { UpdateMachineEngineHours$Plain$Params } from '../fn/equipments/update-machine-engine-hours-plain';

@Injectable({ providedIn: 'root' })
export class EquipmentsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `equipmentsControllerSearchEquipmentrequest()` */
  static readonly EquipmentsControllerSearchEquipmentrequestPath = '/equipments/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipment$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipment$Plain$Response(params?: SearchEquipment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return searchEquipment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipment$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipment$Plain(params?: SearchEquipment$Plain$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.searchEquipment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipment$Response(params?: SearchEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return searchEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipment(params?: SearchEquipment$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.searchEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerSearchEquipmentwithDeliverySequencerequestDeliverySequenceId()` */
  static readonly EquipmentsControllerSearchEquipmentwithDeliverySequencerequestDeliverySequenceIdPath = '/equipments/search/delivery-sequence/{deliverySequenceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentwithDeliverySequence$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentwithDeliverySequence$Plain$Response(params: SearchEquipmentwithDeliverySequence$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return searchEquipmentwithDeliverySequence$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentwithDeliverySequence$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentwithDeliverySequence$Plain(params: SearchEquipmentwithDeliverySequence$Plain$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.searchEquipmentwithDeliverySequence$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentwithDeliverySequence()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentwithDeliverySequence$Response(params: SearchEquipmentwithDeliverySequence$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentIndexDtoSearchResponse>> {
    return searchEquipmentwithDeliverySequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentwithDeliverySequence$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentwithDeliverySequence(params: SearchEquipmentwithDeliverySequence$Params, context?: HttpContext): Observable<EquipmentIndexDtoSearchResponse> {
    return this.searchEquipmentwithDeliverySequence$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentIndexDtoSearchResponse>): EquipmentIndexDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerUpdateEquipmentAliasequipmentIdAlias()` */
  static readonly EquipmentsControllerUpdateEquipmentAliasequipmentIdAliasPath = '/equipments/{equipmentId}/{alias}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEquipmentAlias()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateEquipmentAlias$Response(params: UpdateEquipmentAlias$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateEquipmentAlias(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEquipmentAlias$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateEquipmentAlias(params: UpdateEquipmentAlias$Params, context?: HttpContext): Observable<void> {
    return this.updateEquipmentAlias$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetEquipmentDetailsequipmentId()` */
  static readonly EquipmentsControllerGetEquipmentDetailsequipmentIdPath = '/equipments/{equipmentId}/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentDetails$Plain$Response(params: GetEquipmentDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentDetailsForReturnVm>> {
    return getEquipmentDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentDetails$Plain(params: GetEquipmentDetails$Plain$Params, context?: HttpContext): Observable<EquipmentDetailsForReturnVm> {
    return this.getEquipmentDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentDetailsForReturnVm>): EquipmentDetailsForReturnVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentDetails$Response(params: GetEquipmentDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentDetailsForReturnVm>> {
    return getEquipmentDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentDetails(params: GetEquipmentDetails$Params, context?: HttpContext): Observable<EquipmentDetailsForReturnVm> {
    return this.getEquipmentDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentDetailsForReturnVm>): EquipmentDetailsForReturnVm => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerListEquipmentfilter()` */
  static readonly EquipmentsControllerListEquipmentfilterPath = '/equipments/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipment$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipment$Plain$Response(params?: ListEquipment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentSearchResponse>> {
    return listEquipment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipment$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipment$Plain(params?: ListEquipment$Plain$Params, context?: HttpContext): Observable<EquipmentSearchResponse> {
    return this.listEquipment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentSearchResponse>): EquipmentSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipment$Response(params?: ListEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentSearchResponse>> {
    return listEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipment(params?: ListEquipment$Params, context?: HttpContext): Observable<EquipmentSearchResponse> {
    return this.listEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentSearchResponse>): EquipmentSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerListEquipmentProductAreasfilter()` */
  static readonly EquipmentsControllerListEquipmentProductAreasfilterPath = '/equipments/list/productareas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentProductAreas$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductAreas$Plain$Response(params?: ListEquipmentProductAreas$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAreaSearchResponse>> {
    return listEquipmentProductAreas$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentProductAreas$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductAreas$Plain(params?: ListEquipmentProductAreas$Plain$Params, context?: HttpContext): Observable<ProductAreaSearchResponse> {
    return this.listEquipmentProductAreas$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAreaSearchResponse>): ProductAreaSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentProductAreas()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductAreas$Response(params?: ListEquipmentProductAreas$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAreaSearchResponse>> {
    return listEquipmentProductAreas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentProductAreas$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductAreas(params?: ListEquipmentProductAreas$Params, context?: HttpContext): Observable<ProductAreaSearchResponse> {
    return this.listEquipmentProductAreas$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAreaSearchResponse>): ProductAreaSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerListEquipmentProductGroupsfilter()` */
  static readonly EquipmentsControllerListEquipmentProductGroupsfilterPath = '/equipments/search/product-groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentProductGroups$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductGroups$Plain$Response(params?: ListEquipmentProductGroups$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductGroupSearchResponse>> {
    return listEquipmentProductGroups$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentProductGroups$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductGroups$Plain(params?: ListEquipmentProductGroups$Plain$Params, context?: HttpContext): Observable<ProductGroupSearchResponse> {
    return this.listEquipmentProductGroups$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductGroupSearchResponse>): ProductGroupSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentProductGroups()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductGroups$Response(params?: ListEquipmentProductGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductGroupSearchResponse>> {
    return listEquipmentProductGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentProductGroups$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductGroups(params?: ListEquipmentProductGroups$Params, context?: HttpContext): Observable<ProductGroupSearchResponse> {
    return this.listEquipmentProductGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductGroupSearchResponse>): ProductGroupSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerListEquipmentProductSubGroupsfilter()` */
  static readonly EquipmentsControllerListEquipmentProductSubGroupsfilterPath = '/equipments/search/productsubgroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentProductSubGroups$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductSubGroups$Plain$Response(params?: ListEquipmentProductSubGroups$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductSubGroupSearchResponse>> {
    return listEquipmentProductSubGroups$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentProductSubGroups$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductSubGroups$Plain(params?: ListEquipmentProductSubGroups$Plain$Params, context?: HttpContext): Observable<ProductSubGroupSearchResponse> {
    return this.listEquipmentProductSubGroups$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductSubGroupSearchResponse>): ProductSubGroupSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentProductSubGroups()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductSubGroups$Response(params?: ListEquipmentProductSubGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductSubGroupSearchResponse>> {
    return listEquipmentProductSubGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentProductSubGroups$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductSubGroups(params?: ListEquipmentProductSubGroups$Params, context?: HttpContext): Observable<ProductSubGroupSearchResponse> {
    return this.listEquipmentProductSubGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductSubGroupSearchResponse>): ProductSubGroupSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerlistEquipmentProductModelsfilter()` */
  static readonly EquipmentsControllerlistEquipmentProductModelsfilterPath = '/equipments/search/product-models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentProductModels$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductModels$Plain$Response(params?: ListEquipmentProductModels$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductModelSearchResponse>> {
    return listEquipmentProductModels$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentProductModels$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductModels$Plain(params?: ListEquipmentProductModels$Plain$Params, context?: HttpContext): Observable<ProductModelSearchResponse> {
    return this.listEquipmentProductModels$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductModelSearchResponse>): ProductModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentProductModels()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductModels$Response(params?: ListEquipmentProductModels$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductModelSearchResponse>> {
    return listEquipmentProductModels(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentProductModels$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentProductModels(params?: ListEquipmentProductModels$Params, context?: HttpContext): Observable<ProductModelSearchResponse> {
    return this.listEquipmentProductModels$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductModelSearchResponse>): ProductModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerListEquipmentRemoteSupportProductModelsfilter()` */
  static readonly EquipmentsControllerListEquipmentRemoteSupportProductModelsfilterPath = '/equipments/search/remote-support-product-models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentRemoteSupportProductModels$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentRemoteSupportProductModels$Plain$Response(params?: ListEquipmentRemoteSupportProductModels$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductModelSearchResponse>> {
    return listEquipmentRemoteSupportProductModels$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentRemoteSupportProductModels$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentRemoteSupportProductModels$Plain(params?: ListEquipmentRemoteSupportProductModels$Plain$Params, context?: HttpContext): Observable<ProductModelSearchResponse> {
    return this.listEquipmentRemoteSupportProductModels$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductModelSearchResponse>): ProductModelSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEquipmentRemoteSupportProductModels()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentRemoteSupportProductModels$Response(params?: ListEquipmentRemoteSupportProductModels$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductModelSearchResponse>> {
    return listEquipmentRemoteSupportProductModels(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEquipmentRemoteSupportProductModels$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listEquipmentRemoteSupportProductModels(params?: ListEquipmentRemoteSupportProductModels$Params, context?: HttpContext): Observable<ProductModelSearchResponse> {
    return this.listEquipmentRemoteSupportProductModels$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductModelSearchResponse>): ProductModelSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetEquipmentFleetCount()` */
  static readonly EquipmentsControllerGetEquipmentFleetCountPath = '/equipments/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentFleetCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentFleetCount$Plain$Response(params?: GetEquipmentFleetCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FleetCountResponse>> {
    return getEquipmentFleetCount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentFleetCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentFleetCount$Plain(params?: GetEquipmentFleetCount$Plain$Params, context?: HttpContext): Observable<FleetCountResponse> {
    return this.getEquipmentFleetCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FleetCountResponse>): FleetCountResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentFleetCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentFleetCount$Response(params?: GetEquipmentFleetCount$Params, context?: HttpContext): Observable<StrictHttpResponse<FleetCountResponse>> {
    return getEquipmentFleetCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentFleetCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentFleetCount(params?: GetEquipmentFleetCount$Params, context?: HttpContext): Observable<FleetCountResponse> {
    return this.getEquipmentFleetCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<FleetCountResponse>): FleetCountResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerReScopeAllEquipment()` */
  static readonly EquipmentsControllerReScopeAllEquipmentPath = '/equipments/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeAllEquipment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAllEquipment$Plain$Response(params?: ReScopeAllEquipment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeAllEquipment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeAllEquipment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAllEquipment$Plain(params?: ReScopeAllEquipment$Plain$Params, context?: HttpContext): Observable<number> {
    return this.reScopeAllEquipment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeAllEquipment()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAllEquipment$Response(params?: ReScopeAllEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeAllEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeAllEquipment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeAllEquipment(params?: ReScopeAllEquipment$Params, context?: HttpContext): Observable<number> {
    return this.reScopeAllEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerReScopeEquipmentByCompanyBusinessRelationrequest()` */
  static readonly EquipmentsControllerReScopeEquipmentByCompanyBusinessRelationrequestPath = '/equipments/rescope/by-company-businessrelation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeEquipmentByCompanyBusinessRelation$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reScopeEquipmentByCompanyBusinessRelation$Plain$Response(params?: ReScopeEquipmentByCompanyBusinessRelation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeEquipmentByCompanyBusinessRelation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeEquipmentByCompanyBusinessRelation$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reScopeEquipmentByCompanyBusinessRelation$Plain(params?: ReScopeEquipmentByCompanyBusinessRelation$Plain$Params, context?: HttpContext): Observable<number> {
    return this.reScopeEquipmentByCompanyBusinessRelation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeEquipmentByCompanyBusinessRelation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reScopeEquipmentByCompanyBusinessRelation$Response(params?: ReScopeEquipmentByCompanyBusinessRelation$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeEquipmentByCompanyBusinessRelation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeEquipmentByCompanyBusinessRelation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reScopeEquipmentByCompanyBusinessRelation(params?: ReScopeEquipmentByCompanyBusinessRelation$Params, context?: HttpContext): Observable<number> {
    return this.reScopeEquipmentByCompanyBusinessRelation$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerReScopeEquipmentByProductrequest()` */
  static readonly EquipmentsControllerReScopeEquipmentByProductrequestPath = '/equipments/rescope/by-product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeEquipmentByProduct$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reScopeEquipmentByProduct$Plain$Response(params?: ReScopeEquipmentByProduct$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeEquipmentByProduct$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeEquipmentByProduct$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reScopeEquipmentByProduct$Plain(params?: ReScopeEquipmentByProduct$Plain$Params, context?: HttpContext): Observable<number> {
    return this.reScopeEquipmentByProduct$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeEquipmentByProduct()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reScopeEquipmentByProduct$Response(params?: ReScopeEquipmentByProduct$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return reScopeEquipmentByProduct(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeEquipmentByProduct$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reScopeEquipmentByProduct(params?: ReScopeEquipmentByProduct$Params, context?: HttpContext): Observable<number> {
    return this.reScopeEquipmentByProduct$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerReScopeEquipmentAllProductModels()` */
  static readonly EquipmentsControllerReScopeEquipmentAllProductModelsPath = '/equipments/rescope/product-models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeEquipmentAllProductModels$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeEquipmentAllProductModels$Plain$Response(params?: ReScopeEquipmentAllProductModels$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return reScopeEquipmentAllProductModels$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeEquipmentAllProductModels$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeEquipmentAllProductModels$Plain(params?: ReScopeEquipmentAllProductModels$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.reScopeEquipmentAllProductModels$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reScopeEquipmentAllProductModels()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeEquipmentAllProductModels$Response(params?: ReScopeEquipmentAllProductModels$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return reScopeEquipmentAllProductModels(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reScopeEquipmentAllProductModels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reScopeEquipmentAllProductModels(params?: ReScopeEquipmentAllProductModels$Params, context?: HttpContext): Observable<boolean> {
    return this.reScopeEquipmentAllProductModels$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetEquipmentComponentTypesoptions()` */
  static readonly EquipmentsControllerGetEquipmentComponentTypesoptionsPath = '/equipments/search/component-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentComponentTypes$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentComponentTypes$Plain$Response(params?: GetEquipmentComponentTypes$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ComponentTypePagedResponse>> {
    return getEquipmentComponentTypes$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentComponentTypes$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentComponentTypes$Plain(params?: GetEquipmentComponentTypes$Plain$Params, context?: HttpContext): Observable<ComponentTypePagedResponse> {
    return this.getEquipmentComponentTypes$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ComponentTypePagedResponse>): ComponentTypePagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentComponentTypes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentComponentTypes$Response(params?: GetEquipmentComponentTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<ComponentTypePagedResponse>> {
    return getEquipmentComponentTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentComponentTypes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentComponentTypes(params?: GetEquipmentComponentTypes$Params, context?: HttpContext): Observable<ComponentTypePagedResponse> {
    return this.getEquipmentComponentTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ComponentTypePagedResponse>): ComponentTypePagedResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerSendMachineUpdateRequestsrequest()` */
  static readonly EquipmentsControllerSendMachineUpdateRequestsrequestPath = '/equipments/send-machine-update-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMachineUpdateRequests()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMachineUpdateRequests$Response(params?: SendMachineUpdateRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendMachineUpdateRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMachineUpdateRequests$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMachineUpdateRequests(params?: SendMachineUpdateRequests$Params, context?: HttpContext): Observable<void> {
    return this.sendMachineUpdateRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetTopEquipmentsEngineHours()` */
  static readonly EquipmentsControllerGetTopEquipmentsEngineHoursPath = '/equipments/top-machines-engine-hours';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTopEquipmentsEngineHours$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopEquipmentsEngineHours$Plain$Response(params?: GetTopEquipmentsEngineHours$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentSignalVm>>> {
    return getTopEquipmentsEngineHours$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTopEquipmentsEngineHours$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopEquipmentsEngineHours$Plain(params?: GetTopEquipmentsEngineHours$Plain$Params, context?: HttpContext): Observable<Array<EquipmentSignalVm>> {
    return this.getTopEquipmentsEngineHours$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentSignalVm>>): Array<EquipmentSignalVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTopEquipmentsEngineHours()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopEquipmentsEngineHours$Response(params?: GetTopEquipmentsEngineHours$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentSignalVm>>> {
    return getTopEquipmentsEngineHours(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTopEquipmentsEngineHours$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopEquipmentsEngineHours(params?: GetTopEquipmentsEngineHours$Params, context?: HttpContext): Observable<Array<EquipmentSignalVm>> {
    return this.getTopEquipmentsEngineHours$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentSignalVm>>): Array<EquipmentSignalVm> => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetEquipmentsEngineHoursStatus()` */
  static readonly EquipmentsControllerGetEquipmentsEngineHoursStatusPath = '/equipments/enginehourstatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsEngineHoursStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsEngineHoursStatus$Plain$Response(params?: GetEquipmentsEngineHoursStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineEngineHourCountResponseVm>> {
    return getEquipmentsEngineHoursStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsEngineHoursStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsEngineHoursStatus$Plain(params?: GetEquipmentsEngineHoursStatus$Plain$Params, context?: HttpContext): Observable<MachineEngineHourCountResponseVm> {
    return this.getEquipmentsEngineHoursStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineEngineHourCountResponseVm>): MachineEngineHourCountResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsEngineHoursStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsEngineHoursStatus$Response(params?: GetEquipmentsEngineHoursStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineEngineHourCountResponseVm>> {
    return getEquipmentsEngineHoursStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsEngineHoursStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsEngineHoursStatus(params?: GetEquipmentsEngineHoursStatus$Params, context?: HttpContext): Observable<MachineEngineHourCountResponseVm> {
    return this.getEquipmentsEngineHoursStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineEngineHourCountResponseVm>): MachineEngineHourCountResponseVm => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerSearchOperationSitesrefinementOptions()` */
  static readonly EquipmentsControllerSearchOperationSitesrefinementOptionsPath = '/equipments/sites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchOperationSites$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites$Plain$Response(params?: SearchOperationSites$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MapDtoSearchResponse>> {
    return searchOperationSites$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchOperationSites$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites$Plain(params?: SearchOperationSites$Plain$Params, context?: HttpContext): Observable<MapDtoSearchResponse> {
    return this.searchOperationSites$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MapDtoSearchResponse>): MapDtoSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchOperationSites()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites$Response(params?: SearchOperationSites$Params, context?: HttpContext): Observable<StrictHttpResponse<MapDtoSearchResponse>> {
    return searchOperationSites(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchOperationSites$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchOperationSites(params?: SearchOperationSites$Params, context?: HttpContext): Observable<MapDtoSearchResponse> {
    return this.searchOperationSites$Response(params, context).pipe(
      map((r: StrictHttpResponse<MapDtoSearchResponse>): MapDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerUpdateMachineEngineHoursequipmentIdSignalForSaveDto()` */
  static readonly EquipmentsControllerUpdateMachineEngineHoursequipmentIdSignalForSaveDtoPath = '/equipments/{equipmentId}/engine-hours';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMachineEngineHours$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMachineEngineHours$Plain$Response(params: UpdateMachineEngineHours$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateMachineEngineHours$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMachineEngineHours$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMachineEngineHours$Plain(params: UpdateMachineEngineHours$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.updateMachineEngineHours$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMachineEngineHours()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMachineEngineHours$Response(params: UpdateMachineEngineHours$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return updateMachineEngineHours(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMachineEngineHours$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMachineEngineHours(params: UpdateMachineEngineHours$Params, context?: HttpContext): Observable<boolean> {
    return this.updateMachineEngineHours$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetEquipmentsByProductidproductIdTakeSkip()` */
  static readonly EquipmentsControllerGetEquipmentsByProductidproductIdTakeSkipPath = '/equipments/getbyproduct/{productId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsByProductid$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsByProductid$Plain$Response(params: GetEquipmentsByProductid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentDtoResponse>> {
    return getEquipmentsByProductid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsByProductid$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsByProductid$Plain(params: GetEquipmentsByProductid$Plain$Params, context?: HttpContext): Observable<EquipmentDtoResponse> {
    return this.getEquipmentsByProductid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentDtoResponse>): EquipmentDtoResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsByProductid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsByProductid$Response(params: GetEquipmentsByProductid$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentDtoResponse>> {
    return getEquipmentsByProductid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsByProductid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsByProductid(params: GetEquipmentsByProductid$Params, context?: HttpContext): Observable<EquipmentDtoResponse> {
    return this.getEquipmentsByProductid$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentDtoResponse>): EquipmentDtoResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetEquipmentsByKitkitProductIdTakeSkip()` */
  static readonly EquipmentsControllerGetEquipmentsByKitkitProductIdTakeSkipPath = '/equipments/getbykit/{kitProductId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsByKit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsByKit$Plain$Response(params: GetEquipmentsByKit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentDtoResponse>> {
    return getEquipmentsByKit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsByKit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsByKit$Plain(params: GetEquipmentsByKit$Plain$Params, context?: HttpContext): Observable<EquipmentDtoResponse> {
    return this.getEquipmentsByKit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentDtoResponse>): EquipmentDtoResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsByKit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsByKit$Response(params: GetEquipmentsByKit$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentDtoResponse>> {
    return getEquipmentsByKit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsByKit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentsByKit(params: GetEquipmentsByKit$Params, context?: HttpContext): Observable<EquipmentDtoResponse> {
    return this.getEquipmentsByKit$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentDtoResponse>): EquipmentDtoResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetEquipmentsByProductSearchFilterrequest()` */
  static readonly EquipmentsControllerGetEquipmentsByProductSearchFilterrequestPath = '/equipments/equipmentfilter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsByProductSearchFilter$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsByProductSearchFilter$Plain$Response(params?: GetEquipmentsByProductSearchFilter$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProductSearchEquipmentFilterDto>> {
    return getEquipmentsByProductSearchFilter$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsByProductSearchFilter$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsByProductSearchFilter$Plain(params?: GetEquipmentsByProductSearchFilter$Plain$Params, context?: HttpContext): Observable<NewProductSearchEquipmentFilterDto> {
    return this.getEquipmentsByProductSearchFilter$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProductSearchEquipmentFilterDto>): NewProductSearchEquipmentFilterDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentsByProductSearchFilter()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsByProductSearchFilter$Response(params?: GetEquipmentsByProductSearchFilter$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProductSearchEquipmentFilterDto>> {
    return getEquipmentsByProductSearchFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentsByProductSearchFilter$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentsByProductSearchFilter(params?: GetEquipmentsByProductSearchFilter$Params, context?: HttpContext): Observable<NewProductSearchEquipmentFilterDto> {
    return this.getEquipmentsByProductSearchFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProductSearchEquipmentFilterDto>): NewProductSearchEquipmentFilterDto => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetMachineFilterForEquipmentsrequest()` */
  static readonly EquipmentsControllerGetMachineFilterForEquipmentsrequestPath = '/equipments/machinefilter/machines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForEquipments$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForEquipments$Plain$Response(params?: GetMachineFilterForEquipments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForEquipments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForEquipments$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForEquipments$Plain(params?: GetMachineFilterForEquipments$Plain$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForEquipments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForEquipments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForEquipments$Response(params?: GetMachineFilterForEquipments$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForEquipments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForEquipments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForEquipments(params?: GetMachineFilterForEquipments$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForEquipments$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetMachineFilterForPartsManualsrequest()` */
  static readonly EquipmentsControllerGetMachineFilterForPartsManualsrequestPath = '/equipments/machinefilter/partsmanuals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForPartsManuals$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForPartsManuals$Plain$Response(params?: GetMachineFilterForPartsManuals$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForPartsManuals$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForPartsManuals$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForPartsManuals$Plain(params?: GetMachineFilterForPartsManuals$Plain$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForPartsManuals$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForPartsManuals()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForPartsManuals$Response(params?: GetMachineFilterForPartsManuals$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForPartsManuals(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForPartsManuals$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForPartsManuals(params?: GetMachineFilterForPartsManuals$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForPartsManuals$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetMachineFilterForElectronicManualsrequest()` */
  static readonly EquipmentsControllerGetMachineFilterForElectronicManualsrequestPath = '/equipments/machinefilter/electronicmanuals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForElectronicManuals$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForElectronicManuals$Plain$Response(params?: GetMachineFilterForElectronicManuals$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForElectronicManuals$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForElectronicManuals$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForElectronicManuals$Plain(params?: GetMachineFilterForElectronicManuals$Plain$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForElectronicManuals$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForElectronicManuals()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForElectronicManuals$Response(params?: GetMachineFilterForElectronicManuals$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForElectronicManuals(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForElectronicManuals$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForElectronicManuals(params?: GetMachineFilterForElectronicManuals$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForElectronicManuals$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetMachineFilterForKitrequest()` */
  static readonly EquipmentsControllerGetMachineFilterForKitrequestPath = '/equipments/machinefilter/kits';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForKit$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForKit$Plain$Response(params?: GetMachineFilterForKit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForKit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForKit$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForKit$Plain(params?: GetMachineFilterForKit$Plain$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForKit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForKit()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForKit$Response(params?: GetMachineFilterForKit$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForKit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForKit$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForKit(params?: GetMachineFilterForKit$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForKit$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetMachineFilterForLocationrequest()` */
  static readonly EquipmentsControllerGetMachineFilterForLocationrequestPath = '/equipments/machinefilter/locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForLocation$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForLocation$Plain$Response(params?: GetMachineFilterForLocation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForLocation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForLocation$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForLocation$Plain(params?: GetMachineFilterForLocation$Plain$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForLocation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForLocation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForLocation$Response(params?: GetMachineFilterForLocation$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForLocation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForLocation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForLocation(params?: GetMachineFilterForLocation$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForLocation$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetMachineFilterForBulletinrequest()` */
  static readonly EquipmentsControllerGetMachineFilterForBulletinrequestPath = '/equipments/machinefilter/bulletins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForBulletin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForBulletin$Plain$Response(params?: GetMachineFilterForBulletin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForBulletin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForBulletin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForBulletin$Plain(params?: GetMachineFilterForBulletin$Plain$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForBulletin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForBulletin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForBulletin$Response(params?: GetMachineFilterForBulletin$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForBulletin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForBulletin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForBulletin(params?: GetMachineFilterForBulletin$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForBulletin$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetMachineFilterForWarrantyskipTakeQuery()` */
  static readonly EquipmentsControllerGetMachineFilterForWarrantyskipTakeQueryPath = '/equipments/machinefilter/warranty';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForWarranty$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineFilterForWarranty$Plain$Response(params?: GetMachineFilterForWarranty$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterWarrantyResponse>> {
    return getMachineFilterForWarranty$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForWarranty$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineFilterForWarranty$Plain(params?: GetMachineFilterForWarranty$Plain$Params, context?: HttpContext): Observable<MachineFilterWarrantyResponse> {
    return this.getMachineFilterForWarranty$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterWarrantyResponse>): MachineFilterWarrantyResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForWarranty()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineFilterForWarranty$Response(params?: GetMachineFilterForWarranty$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterWarrantyResponse>> {
    return getMachineFilterForWarranty(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForWarranty$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineFilterForWarranty(params?: GetMachineFilterForWarranty$Params, context?: HttpContext): Observable<MachineFilterWarrantyResponse> {
    return this.getMachineFilterForWarranty$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterWarrantyResponse>): MachineFilterWarrantyResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGridSearchForOrganizationoptionsOrganizationId()` */
  static readonly EquipmentsControllerGridSearchForOrganizationoptionsOrganizationIdPath = '/equipments/searchgrid/organization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain$Response(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Response(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetMachineFilterForPromotionsrequestPromotionId()` */
  static readonly EquipmentsControllerGetMachineFilterForPromotionsrequestPromotionIdPath = '/equipments/machinefilter/promotions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForPromotions$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForPromotions$Plain$Response(params?: GetMachineFilterForPromotions$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForPromotions$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForPromotions$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForPromotions$Plain(params?: GetMachineFilterForPromotions$Plain$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForPromotions$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineFilterForPromotions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForPromotions$Response(params?: GetMachineFilterForPromotions$Params, context?: HttpContext): Observable<StrictHttpResponse<MachineFilterResponse>> {
    return getMachineFilterForPromotions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineFilterForPromotions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMachineFilterForPromotions(params?: GetMachineFilterForPromotions$Params, context?: HttpContext): Observable<MachineFilterResponse> {
    return this.getMachineFilterForPromotions$Response(params, context).pipe(
      map((r: StrictHttpResponse<MachineFilterResponse>): MachineFilterResponse => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetEquipmentSignalsserial()` */
  static readonly EquipmentsControllerGetEquipmentSignalsserialPath = '/equipments/{serial}/equipmentsignals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentSignals$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSignals$Plain$Response(params: GetEquipmentSignals$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentSignalDto>>> {
    return getEquipmentSignals$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentSignals$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSignals$Plain(params: GetEquipmentSignals$Plain$Params, context?: HttpContext): Observable<Array<EquipmentSignalDto>> {
    return this.getEquipmentSignals$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentSignalDto>>): Array<EquipmentSignalDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentSignals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSignals$Response(params: GetEquipmentSignals$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentSignalDto>>> {
    return getEquipmentSignals(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentSignals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSignals(params: GetEquipmentSignals$Params, context?: HttpContext): Observable<Array<EquipmentSignalDto>> {
    return this.getEquipmentSignals$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentSignalDto>>): Array<EquipmentSignalDto> => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetManualAddedEquipmentSignalsequipmentId()` */
  static readonly EquipmentsControllerGetManualAddedEquipmentSignalsequipmentIdPath = '/equipments/{equipmentId}/manualaddedquipmentsignal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualAddedEquipmentSignals$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualAddedEquipmentSignals$Plain$Response(params: GetManualAddedEquipmentSignals$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentSignalDto>>> {
    return getManualAddedEquipmentSignals$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getManualAddedEquipmentSignals$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualAddedEquipmentSignals$Plain(params: GetManualAddedEquipmentSignals$Plain$Params, context?: HttpContext): Observable<Array<EquipmentSignalDto>> {
    return this.getManualAddedEquipmentSignals$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentSignalDto>>): Array<EquipmentSignalDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualAddedEquipmentSignals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualAddedEquipmentSignals$Response(params: GetManualAddedEquipmentSignals$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentSignalDto>>> {
    return getManualAddedEquipmentSignals(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getManualAddedEquipmentSignals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualAddedEquipmentSignals(params: GetManualAddedEquipmentSignals$Params, context?: HttpContext): Observable<Array<EquipmentSignalDto>> {
    return this.getManualAddedEquipmentSignals$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentSignalDto>>): Array<EquipmentSignalDto> => r.body)
    );
  }

  /** Path part for operation `equipmentsControllerGetEngineHoursIfEquipmentHasKnowledgeBoxequipmentId()` */
  static readonly EquipmentsControllerGetEngineHoursIfEquipmentHasKnowledgeBoxequipmentIdPath = '/equipments/{equipmentId}/hasknowledgebox/enginehours';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEngineHoursIfEquipmentHasKnowledgeBox$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEngineHoursIfEquipmentHasKnowledgeBox$Plain$Response(params: GetEngineHoursIfEquipmentHasKnowledgeBox$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentSignalDto>>> {
    return getEngineHoursIfEquipmentHasKnowledgeBox$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEngineHoursIfEquipmentHasKnowledgeBox$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEngineHoursIfEquipmentHasKnowledgeBox$Plain(params: GetEngineHoursIfEquipmentHasKnowledgeBox$Plain$Params, context?: HttpContext): Observable<Array<EquipmentSignalDto>> {
    return this.getEngineHoursIfEquipmentHasKnowledgeBox$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentSignalDto>>): Array<EquipmentSignalDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEngineHoursIfEquipmentHasKnowledgeBox()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEngineHoursIfEquipmentHasKnowledgeBox$Response(params: GetEngineHoursIfEquipmentHasKnowledgeBox$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentSignalDto>>> {
    return getEngineHoursIfEquipmentHasKnowledgeBox(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEngineHoursIfEquipmentHasKnowledgeBox$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEngineHoursIfEquipmentHasKnowledgeBox(params: GetEngineHoursIfEquipmentHasKnowledgeBox$Params, context?: HttpContext): Observable<Array<EquipmentSignalDto>> {
    return this.getEngineHoursIfEquipmentHasKnowledgeBox$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentSignalDto>>): Array<EquipmentSignalDto> => r.body)
    );
  }

}
