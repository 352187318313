/* tslint:disable */
/* eslint-disable */
export enum BlobContentType {
  Document = 0,
  Bulletin = 1,
  Image = 2,
  UserFile = 3,
  AuroraCreateItem = 4,
  AuroraAcknowledgeSalesOrder = 5,
  FailedAcknowledgeSalesOrder = 6,
  AuroraConfirmDespatch = 7,
  AuroraPublishExpectedDeliveryDate = 8,
  AuroraPublishWaybill = 9,
  AuroraCreditLimit = 10,
  MessageLog = 11,
  IntegrationBulletin = 12,
  NotSet = 13,
  DocumotoZipFile = 14,
  EmailFile = 15,
  BillOfMaterialFiles = 16,
  DocumotoZipFileDest = 17,
  WarrantyClaimAttachmentThumbnail = 18,
  ProcessedAuroraAcknowledgeSalesOrder = 19,
  AuroraStreamServe = 20,
  DownloadedManuals = 21,
  ProductImages = 22,
  AuroraMessageConfirmation = 23,
  TDModels = 24,
  MDGCustomerNotification = 25,
  DocumotoMediaContentFiles = 26,
  BlobLogFiles = 27,
  DocumotoMediaXmlFiles = 28
}
