import { BroadcastService } from './../broadcast-service/broadcast.service';
import { Injectable, ErrorHandler } from "@angular/core";
import { NoticebarService } from "../noticebar-service/noticebar.service";
import { LogService } from '../logservice';
import { ApplicationStateService } from '../application-state/application-state.service';
import { ApplicationStates } from '../application-state/application-state.types';
import { ApplicationInsightsService } from '../application-insights/application-insights.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { Router } from '@angular/router';

export class MibpException {
  constructor(params: {
    type?: 'unknown' | 'thirdparty' | 'frontend' | 'backend',
    message?: string,
    stack?: any,
    code?: number,
    isFatal?: boolean
  } | string) {

    if (typeof params === 'string') {
      this.message = params;
    } else if (params) {
      this.type  = params.type || 'unknown';
      this.message = params.message || '';
      this.stack = params.stack || {};
      this.code = params.code || 500;
      this.isFatal = !!params.isFatal;
    }

  }

  type: 'unknown'| 'thirdparty' | 'frontend' | 'backend' = 'unknown';
  message = '';
  stack: any = {};
  code = 500;
  isFatal = true;
}

@Injectable({
  providedIn: 'root'
})
export class MibpErrorHandler implements ErrorHandler {
  private previousError: string;

  constructor(private noticebarService: NoticebarService,
    private broadcastService: BroadcastService,
    private applicationStateService: ApplicationStateService,
    private log: LogService,
    private applicationInsightsService: ApplicationInsightsService,
    private router: Router) {
  }

  public handleError(error: any): void {    
    const currentError = error.toString();

    // If it´s a repeating error -> error overlay is already showing so we can silently continue
    if (this.previousError == currentError) {
      return;
    }
    
    this.previousError = currentError;
    
    // eslint-disable-next-line no-console
    console.error("Error", error);

    this.broadcastService.setFrontendError(error);
    this.applicationInsightsService.logException(error);
    if (error && error?.message?.indexOf('AADB2C') !== -1) {
      return;
    }

    if(error.error?.errors){
      const errorStack = error.error?.errors;
      if(errorStack && errorStack[errorStack.length - 1].exceptionType == "MibpSessionRevokedException"){
        this.router.navigateByUrl('en/unauthorized');
        return;
      }
    }


    if (this.applicationStateService.currentState?.state === ApplicationStates.Loaded) {
      this.broadcastService.setUnhandledException(error);  
      return;
    }

    const mibpError = this.tryParseMibpError(Object.assign({}, error));

    if (mibpError) {
      this.applicationStateService.setState({
        state: ApplicationStates.UnhandledException,
        error: true,
        internalStatus: 'error-handler.service',
        resourceStringKey: 'General_Error_InlineTitle',
        textFallback: 'Whoops! Something has gone wrong',
        exception: mibpError,
        stopStartupGuardProcessing: true
      });
    } else {
      this.applicationStateService.setState({
        state: ApplicationStates.UnhandledException,
        error: true,
        internalStatus: 'error-handler.service',
        resourceStringKey: 'General_Error_InlineTitle',
        textFallback: 'Whoops! Something has gone wrong',
        exception: error,
        stack: error?.rejection?.errorMessage || error?.message,
        stopStartupGuardProcessing: true
      });      
    }    
  }

  private tryParseMibpError(error: any): MibpException {

    this.log.warn("tryParseMibpError", Object.assign({}, error));

    if (error && error.originalStack && error.originalStack.indexOf("TypeError: Cannot read property 'visitExpression' of undefined") !== -1) {
      this.log.warn(`TIP: Thrown error may be a cause of duplicate , characters in a newly edited file`);
    }

    if (error && error instanceof MibpException) {
      return error;
    }
    if (error && error.rejection && error.rejection instanceof MibpException) {
      return error.rejection;
    } else {
      this.log.debug("Error is not an instance of mibpExceptioN", error);
    }

    const e = new MibpException({});
    if (error) {

      const errStr = error.toString();
      if (errStr.indexOf('Failed to invoke') !== -1 && errStr.indexOf('due to an error on the server') !== -1) {
        const method = errStr.match(/to invoke '(.*?)'/);
        e.type = 'backend';
        e.message = `An error occured invoking ${method ? ` the '${method[1]}'` : ' a '} SignalR Hub Method`;
        e.isFatal = false;
        const m = errStr.match(/Error: (.+)/g);
        if (m) {
          e.stack.errorMessage = m.length === 2 ? m[1] : m[0];
          e.message = `[SignalR] ${e.stack.errorMessage.substring(7)}`;
        }

        if (error.originalStack) {
          e.stack.originalStack = error.originalStack;
        }
        return e;
      }
    }
    return null;
  }

  displayErrorNoticeForSpecificException(error: { exceptionTypeName: string, defaultErrorText: string, httpErrorResponse: HttpErrorResponse }) {
    let errors = error.httpErrorResponse.error.errors;

    if (!errors && error.httpErrorResponse.error) {
        const parsedError = JSON.parse(error.httpErrorResponse.error);
        errors = parsedError.errors;
    }

    const specificException = errors?.find(e => e.exceptionType == error.exceptionTypeName);
    if (specificException) {
      this.noticebarService.showText(specificException.description, NoticeType.Error, false);

    } else {
      this.noticebarService.showText(error.defaultErrorText, NoticeType.Error, false);
    }
  }
}
