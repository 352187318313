import { OnInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { Component, ViewChild, OnDestroy, Input } from '@angular/core';
import { PDFDocumentProxy, PDFProgressData, PdfViewerComponent, ZoomScale } from 'ng2-pdf-viewer';
import { FormattingService, LocalizationService, LogService, MibpLogger } from 'root/services';
import { Subject, Subscription, debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';

interface OutlineNode {
  title: any;
  dest: any;
  isExpanded: boolean;
  childNodes: OutlineNode[];
  isSelected?: boolean;
}

@Component({
  selector: 'mibp-pdf-viewer',
  templateUrl: './mibp-pdf-viewer.component.html',
  styleUrls: ['./mibp-pdf-viewer.component.scss']
})
export class MibpPdfViewerComponent implements OnInit, OnDestroy {

  @Input() url: string;
  @Input() filename: string;
  @Input() fileSize: number;
  @Input() showToolbar = true;
  @Input() height: string;

  @Output() loadCompleted = new EventEmitter();
  @Output() loadError = new EventEmitter();

  @ViewChild('pdfDocumentContainer') pdfDocumentContainerRef: ElementRef;
  @ViewChild(PdfViewerComponent) pdfComponent!: PdfViewerComponent;

  private pageValueUpdate: Subject<number>;
  private mouseWheelZoomSub: Subscription;
  private pdf: PDFDocumentProxy;
  private selectedOutlineNode: OutlineNode;
  private log: MibpLogger;

  protected outline!: OutlineNode[];
  protected showOutline = false;
  protected zoomScale: ZoomScale = 'page-fit';
  protected latestDestination: string;
  protected numberOfPages: number = 1;
  protected isLoading = true;
  protected autoresize = true;
  protected hasOutline = false;
  protected zoom = 1.0;
  protected fitToPage = true;
  protected currentPageNumber = 1;
  protected showAllPages = true;
  protected error: any = null;
  protected downloadedManualPdfSize?: number;
  protected pdfLoadingStatusText: string;
  protected pdfLoadPercent = 0;

  constructor(
    private localizationService: LocalizationService,
    log: LogService) {
    this.log = log.withPrefix('electronic-manual-details-page');
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    this.mouseWheelZoomSub?.unsubscribe();
    this.pageValueUpdate?.unsubscribe();
  }

  private setupSubscriptions(): void {
    // Zoom using ctrl + mouse wheel
    this.mouseWheelZoomSub = fromEvent<MouseEvent>(window, 'mousewheel', { passive: false })
      .subscribe((e) => {
        if (e.target == this.pdfDocumentContainerRef.nativeElement || this.pdfDocumentContainerRef.nativeElement.contains(e.target as HTMLElement)) {
          if (e.ctrlKey) {
            e.preventDefault();

            if ((<any>e).deltaY > 0) {
              this.changeZoom(-0.1);
            } else {
              this.changeZoom(0.1);
            }
          }
        }
      });

    // We wait 400ms before we switch page in case users continues typing
    this.pageValueUpdate = new Subject<number>();
    this.pageValueUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.currentPageNumber = value;
      });
  }

  protected afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.numberOfPages = pdf.numPages;
    this.isLoading = false;

    // We need to load outline/bookmarks in order to know if there are any
    this.loadOutline();
  }

  protected navigateTo(node: OutlineNode) {
    if (this.selectedOutlineNode) {
      this.selectedOutlineNode.isSelected = false;
    }

    this.latestDestination = node.dest;
    this.selectedOutlineNode = node;
    this.selectedOutlineNode.isSelected = true;
    this.pdfComponent.pdfLinkService.goToDestination(node.dest);
  }

  protected changeZoom(amount: number) {
    // We do not zoom out passed 0.1 or in passed 4 since that will not bring
    // any benefit to the user
    if ((amount < 0 && this.zoom <= 0.2) || (amount > 0 && this.zoom >= 4)) {
      return;
    }

    this.zoom += amount;
  }

  protected changePage(changeNumber: number) {
    this.currentPageNumber += changeNumber;
  }

  protected onPageNumberModelChanged(newPageNumber: number) {
    this.pageValueUpdate.next(newPageNumber);
  }

  protected toggleNode(outlineNode: OutlineNode) {
    outlineNode.isExpanded = !outlineNode.isExpanded;
  }

  protected onProgress(progressData: PDFProgressData) {
    if (!this.fileSize) {
      return;
    }

    const amountLoaded = progressData.loaded;
    this.pdfLoadPercent = Math.ceil((amountLoaded / this.fileSize) * 100);
    this.isLoading = amountLoaded < this.fileSize;
  }

  private loadOutline() {
    this.pdf.getOutline().then((outline: any[]) => {
      if (outline && outline.length > 0) {
        this.outline = this.createOutlineNodeTree(outline);
        this.hasOutline = true;
      } else {
        this.hasOutline = false;
      }
    }).catch(error => {
      this.log.error("Loading outline failed", error);
    });
  }

  private createOutlineNodeTree(outlineNodes: any[]): OutlineNode[] {
    return outlineNodes.map(ol => {
      return {
        title: ol.title,
        dest: ol.dest,
        isExpanded: false,
        childNodes: ol.items?.length > 0 ? this.createOutlineNodeTree(ol.items) : []
      };
    });
  }

  protected setZoomScale(zoomScale: ZoomScale) {
    this.zoom = 1; //Need to reset zoom before changing zoomScale to prevent weird resizing of document
    this.zoomScale = zoomScale;
  }

  protected changeFitToPage() {
    this.fitToPage = !this.fitToPage;
  }

  protected toggleOutline() {
    this.showOutline = !this.showOutline;
  }

  protected download() {
    this.pdf.getData().then((pdfDataArray) => {
      let blob = new Blob([pdfDataArray.buffer], {
        type: 'application/pdf'
      });

      let url = URL.createObjectURL(blob);
      this.openLink(url);

      //Cleanup
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 5000);
    });
  }

  // Creates a link to the given URL that is then "clicked" to trigger download
  private openLink(url: string) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = this.filename;
    a.click();
    document.body.removeChild(a);
  }

  protected trackNode(index: number, node: any) {
    return node.dest;
  }

  protected pageChange(e: number) {
  }

  protected onError(error: any) {
    this.error = error;
    this.loadError.emit();
  }
}
