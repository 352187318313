import { TemplatesApiController } from './../../../../../../mibp-openapi-gen/services/templates-api-controller';
import { NoticeType } from './../../../../../../components/noticebar/noticebar.enum';
import { Component, Input, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { DateRefiner, TextRefiner, Refiner, RefinerChangeEvent, CheckboxListRefiner, CheckboxListItem, RefinerComponent } from "root/components";
import {
  CartService,
  SignalR_DeliverySequence,
  FormattingService,
  FrontendContextService,
  LocalizationService,
  OrderService,
  LogService,
  MibpLogger,
  ApiService,
  NoticebarService,
  BroadcastService
} from "root/services";
import { CartListItem } from "./cart-list";
import { ProductListService } from "../../services";
import { DialogService } from "root/services/dialog/dialog.service";
import { Guid } from "guid-typescript";
import { Router } from "@angular/router";
import { firstValueFrom, Subscription } from "rxjs";
import { CartNameComponent } from '../cart-name/cart-name.component';
import { Observable } from 'rxjs';
import { GoogleTagManagerService } from 'root/services/google-tag-manager-service/google-tag-manager.service';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { PermissionPolicy } from 'root/services/permission/permission.service';
import { AddItemToCartSource, CartForListRequest, CartForListViewModel, CartForListViewModelPagedResponse, CartStatus, CartType, OrderStatus, PagedUserCartsRefinementOptions, PagedUserRefinementOptions, PriceAndAvailabilitySource, SessionDeliverySequenceViewModel, TemplatesRequest } from 'root/mibp-openapi-gen/models';
import { CartsApiController } from 'root/mibp-openapi-gen/controllers';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { ProductListPaginatedComponent } from '../product-list-paginated/product-list-paginated.component';

@Component({
  selector: 'mibp-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnInit, OnDestroy {

  @Input() statuses: CartStatus[] | OrderStatus[] = [];
  @Input() type: 'carts' | 'orders' | 'quotations' | 'privateTemplate' | 'sharedTemplate';
  @Input() requiredPermissionPolicy: PermissionPolicy;
  @Input() requireDeliverySequence = true;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('orders.equipmentId') equipmentId: string;
  @Input() hidePrice: boolean;
  @Input() hideTotalPrice: boolean;
  @Input() hideAvailability: boolean;
  @Input() hideAddToCart = true;
  @Input() hideDelete: boolean;
  @Input() hideQuickAdd = true;
  @Input() skipFocusOnQuickAdd: boolean;
  @Input() hideRelatedButtons: boolean;
  @Input() hideRelatedProducts: boolean;
  @Input() hideMachineLinks: boolean;
  @Input() quickAddButtonTextKey: string;
  @Input() priceAndAvailabilitySource = PriceAndAvailabilitySource.ActiveCart;
  @Input() addItemToCartSource =  AddItemToCartSource.QuickAdd;
  @ViewChild(RefinerComponent) refinerComponent: RefinerComponent;
  @ViewChild(ProductListPaginatedComponent) productListPaginatedComponent: ProductListPaginatedComponent;

  private dateFrom = new DateRefiner('from', 'Global_Filters_DateFilterFromLabel', null, new Date(new Date().getTime() - 730 * 24 * 60 * 60 * 1000));
  private dateTo = new DateRefiner('to', 'Global_Filters_DateFilterToLabel');
  private query = new TextRefiner('query', 'Global_Filters_TextFilterLabel');
  private status = new CheckboxListRefiner('status', 'Global_Status', []);


  cartStatusEnum = CartStatus;
  cartList: CartListItem[] = null;
  currentFilters: {
    from: Date;
    to: Date;
    query: string;
    status: number[];
  } = { from: null, to: null, query: null, status: [] };
  deliverySequenceSubscription: Subscription;
  errorFetchingItems = false;
  hasFilters = false;
  hasRequiredPermissions = true;
  disableDuplicate = false;
  hasShareTemplatePermission: boolean;
  hasUnShareTemplatePermission: boolean;
  hasCreateCartPermission: boolean;
  currentUserId: Guid;
  cartName: CartNameComponent;
  allowEditTitle = false;
  isDeliverySequenceOk = true;
  isLoading = false;
  pageSize = 10;
  properties: { key: string; value: string; valueBeforeKey: boolean; }[] = [];
  refinerConfig: Refiner[] = <Refiner[]>[this.query, this.dateFrom, this.dateTo, this.status];
  skip = 0;
  statusFacets: CheckboxListItem[];
  totalItems = 0;
  log: MibpLogger;
  public cartSubscriptions: Subscription[] = [];
  private stopUsingResources: () => void;
  private addedMessage: string;
  private productsCouldNotbeAddedmessage: string;
  private invalidProductCodeMessage: string;
  constructor(private cartService: CartService,
    private orderService: OrderService,
    private router: Router,
    private frontendContext: FrontendContextService,
    private localizationService: LocalizationService,
    private formattingService: FormattingService,
    private dialogService: DialogService,
    private noticeService: NoticebarService,
    private sessionService: MibpSessionService,
    logger: LogService,
    private cartsController: CartsApiController,
    private templateApiController: TemplatesApiController,
    private broadcast: BroadcastService) {
    this.log = logger.withPrefix('cart-list');
  }


  ngOnInit() {
    if (!this.type) {
      throw new Error('Type parameter missing on mibp-cart-list');
    }
    this.status.hideIfOnlyOne = (this.type === 'carts' || this.type === 'privateTemplate' || this.type === 'sharedTemplate');
    this.disableDuplicate = (this.type === 'privateTemplate' || this.type === 'sharedTemplate');
    this.status.items = this.createStatusRefiner();
    this.checkDeliverySequence(this.sessionService.activeDeliverySequence);
    this.checkPermissions();
    this.setupDeliverySequenceSubscription();

    this.currentUserId = Guid.parse(this.broadcast.snapshot.mibpSession.user.userId);
    this.hasCreateCartPermission = this.frontendContext.testPermission(allPermissionPolicies.canAddToCart);
    this.hasShareTemplatePermission = this.frontendContext.testPermission(allPermissionPolicies.canShareTemplates);
    this.hasUnShareTemplatePermission = this.frontendContext.testPermission(allPermissionPolicies.canUnShareTemplates);

    this.stopUsingResources = this.localizationService.using(['Cart_ProductsSuccessfullyAdded', 'Cart_ProductsCouldNotBeAdded', 'Global_InvalidProductCode'], resourceStrings => {
      this.addedMessage = resourceStrings[0];
      this.productsCouldNotbeAddedmessage = resourceStrings[1];
      this.invalidProductCodeMessage = resourceStrings[2];
    });
  }

  ngOnDestroy() {
    this.deliverySequenceSubscription?.unsubscribe();
    if (this.cartSubscriptions && this.cartSubscriptions.length > 0) {
      this.cartSubscriptions.forEach(cartSubscription => { cartSubscription.unsubscribe(); });
    }
    if (this.stopUsingResources) {
      this.stopUsingResources();
    }
  }

  private setupDeliverySequenceSubscription() {
    this.deliverySequenceSubscription = this.sessionService.activeDeliverySequence$.subscribe(deliverySequence => {
      if (this.requireDeliverySequence && !deliverySequence) {
        this.isDeliverySequenceOk = false;
      } else {
        this.isDeliverySequenceOk = true;
        }
      if (this.deliverySequenceSubscription) {
        this.refreshItems();
      }
    });
  }

  private checkDeliverySequence(deliverySequence: SessionDeliverySequenceViewModel) {
    if (this.requireDeliverySequence && !deliverySequence) {
      this.isDeliverySequenceOk = false;
    } else {
      this.isDeliverySequenceOk = true;
    }
  }

  private checkPermissions() {
    if (this.requiredPermissionPolicy) {
      this.hasRequiredPermissions = this.frontendContext.testPermission(this.requiredPermissionPolicy);
    }
  }

  onRefinerFilterChanged(refinerChange: RefinerChangeEvent) {

    if (!this.type) {
      return;
    }

    this.currentFilters = refinerChange.filters;
    this.currentFilters.status = refinerChange.filters.status ? refinerChange.filters.status.map(f => parseInt(f, 10)) : null;
    this.hasFilters = !!this.currentFilters.from || !!this.currentFilters.to || !!this.currentFilters.query;
    this.skip = 0;

    this.refreshItems();
  }

  createStatusRefiner(): CheckboxListItem[] {
    if ((!this.statuses || this.statuses.length === 0) || this.type !== 'carts') {
      return [];
    }

    const prefix = 'Carts_CartStatus_',
      s = <number[]>this.statuses;

    const refiners: CheckboxListItem[] = s.map(f => {
      return {
        resourceKey: `${prefix}${f}`,
        value: f.toString()
      };
    });
    return refiners;
  }

  collapseCart(item: CartListItem): void {
    item.productListItems = null;
  }

  showAside(status: CartStatus): boolean {
    if (this.type === 'privateTemplate' || this.type === 'sharedTemplate') {
      return false;
    } else if ((this.type === 'orders' || this.type === 'quotations') || status === CartStatus.Saved) {
      return true;
    }
    return false;
  }

  canExpand(item: CartListItem) {
    if (this.type === 'orders' || this.type === 'quotations') {
      return false;
    }
    return true;
  }

  removeSavedCart(item: CartListItem) {

    this.dialogService.prompt('Carts_Saved_ConfirmRemovePrompt', 'delete#Global_Dialog_Yes', 'cancel#Global_Dialog_No')
      .then(buttonId => {
        if (buttonId === 'delete') {
          this.isLoading=true;
          firstValueFrom(this.cartsController.deleteCart({id:item.id})).then(ok => {
            const ix = this.cartList.findIndex(listItem => listItem.id.toString() === item.id.toString());
            this.cartList.splice(ix, 1);
            this.isLoading = false;
          }).catch(error=>{
            this.log.error('Error while removing saved cart', error);
            this.isLoading = false;
            this.noticeService.show('Cart_RemoveSavedCart_Error', NoticeType.Error);
          });

        }
      });
  }

  activateSavedCart(item: CartListItem) {
    firstValueFrom(this.cartsController.checkCartHasDuplicates({cartId:item.id})).then((hasDuplicates) => {
      if (hasDuplicates === true) {
        this.dialogService.promptWithWarning('Carts_Saved_ConfirmActivatePrompt', 'CartsDialog_ActivateSaved_Duplicate_Products', 'activate#Global_Dialog_Yes', 'cancel#Global_Dialog_No')
          .then(buttonId => {
            if (buttonId === 'activate') {
              firstValueFrom(this.cartsController.restoreSavedCart({cartId:item.id})).then(count=>{
                this.broadcast.setCartEvent({count:count});
                this.router.navigateByUrl(this.frontendContext.Navigation.ensureUrlLanguage('/home/shop/cart'));
              }).catch(error=>{
                this.log.error('Error activating saved cart',error);
                this.noticeService.showText('Error activating saved cart', NoticeType.Error, true);
              });
            }
          });
      } else {
        this.dialogService.prompt('Carts_Saved_ConfirmActivatePrompt', 'activate#Global_Dialog_Yes', 'cancel#Global_Dialog_No')
          .then(buttonId => {
            if (buttonId === 'activate') {
              firstValueFrom(this.cartsController.restoreSavedCart({cartId:item.id})).then(count=>{
                this.broadcast.setCartEvent({count:count});
                this.router.navigateByUrl(this.frontendContext.Navigation.ensureUrlLanguage('/home/shop/cart'));
              }).catch(error=>{
                this.log.error('Error activating saved cart',error);
                this.noticeService.showText('Error activating saved cart', NoticeType.Error, true);
              });
            }
          });
      }
    }).catch(error=>{
      this.log.error('Error fetching cart duplicates', error);
      this.noticeService.showText('Error activating saved cart', NoticeType.Error, true);
    });

  }

  /**
  * Occurs when a collapsable item is expanded
  * Or when the title is clicked, if collapsable is not expandable
  */
  expandOrVisitItem(item: CartListItem, index: number) {

    if (this.type === 'orders') {
      this.router.navigate([`/${this.localizationService.getLang()}/home/cart/orders/${encodeURIComponent(item.id)}`]);
      return;
    } else if (this.type === 'quotations') {
      this.router.navigate([`/${this.localizationService.getLang()}/home/cart/quotations/${encodeURIComponent(item.id)}`]);
      return;
    } else if (this.type === 'carts') {
      this.productListPaginatedComponent.refreshCartItems(true);
    }
  }



  private fetchOrders() {
    this.isLoading = true;

    this.cartList = [];
    let quotationproperties = [];

    const refinementOptions: PagedUserRefinementOptions = {
      skip: this.skip,
      take: this.pageSize,
      from: this.currentFilters?.from?.toString(),
      to: this.currentFilters?.to?.toString(),
      query: this.currentFilters.query,
      status: this.currentFilters.status,
      linkedSerialNumberId: this.equipmentId
    };

    this.orderService.List(refinementOptions, this.type === 'quotations').then(f => {
      this.totalItems = f.count;
      this.cartList = f.items.map<CartListItem>(fa => {
        if (fa.quotationExpiryDate && fa.status === 1) {
          if (new Date(fa.quotationExpiryDate) < new Date(new Date().toDateString())) {
            fa.status = 7;
          }
        }

        if (fa.quotationType === 0) {
          quotationproperties = [
            { valueResourceKey: 'Global_Order', value: fa.number },
            { value: ` ${fa.lineCount} `, valueSuffixResourceKey: 'Global_Items' },
            {
              key: 'Global_Weight',
              value: ` ${this.formattingService.formatWeight(fa.weight)} `,
              valueSuffixResourceKey: 'Global_UoM_KG',
              valueSuffixResourceKeyIsUpperCase: true
            },
            { valueResourceKey: 'Carts_OrderStatus_' + fa.status, valueIsBold: true },
            { valueResourceKey: 'Carts_Created', value: this.formattingService.formatDateTime(fa.createdDate), tooltip: this.formattingService.getTooltipString(fa.createdDate) }
          ];
        } else {

          // {{ cart.itemCount || 0 }} <mibp-resource-string key="Global_Items"></mibp-resource-string>  {{ cart.properties?.length > 0 ? ' | ' : '' }}

          quotationproperties = [
            { valueResourceKey: 'Global_Quotation', value: ` ${fa.number}` },
            { value: ` ${fa.lineCount} `, valueSuffixResourceKey: 'Global_Items' },
            {
              key: 'Global_Weight',
              value: ` ${this.formattingService.formatWeight(fa.weight)} `,
              valueSuffixResourceKey: 'Global_UoM_KG',
              valueSuffixResourceKeyIsUpperCase: true
            },
            { valueResourceKey: 'Carts_OrderStatus_' + fa.status, valueIsBold: true },
            { valueResourceKey: 'Carts_Created', value: this.formattingService.formatDateTime(fa.createdDate), tooltip: this.formattingService.getTooltipString(fa.createdDate) }
          ];
        }

        return {
          id: fa.id.toString(),
          itemCount: fa.lineCount,
          title: fa.name,
          showItems: false,
          status: fa.status,
          quotationType: fa.quotationType,
          properties: quotationproperties,
          type:fa.quotationType
        };
      });

      const statusFacets = f.facets.find(fc => fc.key === 'Status');
      if (this.status.items.length == 0) {
        if (statusFacets) {
          this.status.items = statusFacets.facetValues.map(val => <CheckboxListItem>{
            resourceKey: 'Carts_OrderStatus_' + val.value, value: val.value, text: `(${val.count})`
          });
        } else {
          this.status.items = [];
        }
      }
      this.isLoading = false;
    },
    err => {
      this.errorFetchingItems = true;
      this.isLoading = false;
    });

  }

  fetchTemplates(type: CartType) {

    this.isLoading = true;
    this.cartList = [];

    const refinementOptions: PagedUserCartsRefinementOptions = {
      skip: this.skip,
      take: this.pageSize,
      from: this.currentFilters.from?.toDateString(),
      to: this.currentFilters.to?.toDateString(),
      query: this.currentFilters.query,
      cartStatus: this.currentFilters.status,
      sortBy:null
    };
    let templateproperties = [];
    this.templateApiController.getTemplates({body:<TemplatesRequest>{options:refinementOptions, types: <CartType[]>[type]}}).subscribe(d => {
      this.log.debug('cartItems', d);
      this.totalItems = d.totalCount;

      this.cartList = d.items.map<CartListItem>(fa => {
        templateproperties = [
          { value: ` ${fa.itemCount} `, valueSuffixResourceKey: 'Global_Items' },
          { valueResourceKey: 'Carts_Created', value: this.formattingService.formatDateTime(fa.createdDate), tooltip: this.formattingService.getTooltipString(fa.createdDate) }
        ];
        return {
          id: fa.id.toString(),
          ownerId: Guid.parse(fa.ownerId),
          itemCount: fa.itemCount,
          title: fa.name,
          showItems: false,
          status: fa.status,
          properties: templateproperties,
          type:fa.type
        };
      });

      this.isLoading = false;
    },
    err => {
      this.errorFetchingItems = true;
      this.isLoading = false;
    });
  }

  shareCart(item: CartListItem) {
    //Todo - remove this method while cleaning CartListComponent.
    // this.dialogService.prompt('Templates_Share_ConfirmPrompt', 'share#Templates_ShareTemplate', 'cancel#Global_CancelButton')
    //   .then(buttonId => {
    //     if (buttonId === 'share') {
    //       this.cartService.shareCart(Guid.parse(item.id));
    //       this.googleTagManagerService.pushCustomEvent('Template_ShareTemplate');
    //     }
    //   });
  }

  UnShareCart(item: CartListItem) {
    //Todo - remove this method while cleaning CartListComponent.
    // this.dialogService.prompt('Templates_UnShare_ConfirmPrompt', 'unshare#Templates_UnShareTemplate', 'cancel#Global_CancelButton')
    //   .then(buttonId => {
    //     if (buttonId === 'unshare') {
    //       this.cartService.unShareCart(Guid.parse(item.id));
    //     }
    //   });
  }

  addItemsToActiveCart(item: CartListItem) {
    firstValueFrom(this.templateApiController.addTemplateToCart({templateCartId: item.id}));
  }


  changePage(e: any) {
    if (e.isUserEvent) {
      this.skip = e.startIndex;
      this.refreshItems();
    }
  }
  itemAdded(id: string): void {
    const cart = this.cartList.find(x => x.id == id);
    const cartIndex = this.cartList.indexOf(cart);
    const prop = cart.properties.find(i => i.valueSuffixResourceKey == 'Global_Items');
    const index = cart.properties.indexOf(prop);
    this.cartList[cartIndex].properties[index].value = ' ' + (Number.parseInt(this.cartList[cartIndex].properties[index].value, 10) + 1).toString() + ' ';
  }
  itemremoved(id: string): void {
    const cart = this.cartList.find(x => x.id == id);
    const cartIndex = this.cartList.indexOf(cart);
    const prop = cart.properties.find(i => i.valueSuffixResourceKey == 'Global_Items');
    const index = cart.properties.indexOf(prop);
    this.cartList[cartIndex].properties[index].value = ' ' + (Number.parseInt(this.cartList[cartIndex].properties[index].value, 10) - 1).toString() + ' ';
  }
  onFinishedEditingCartTitle(cart: CartListItem, newTitle: string) {
    firstValueFrom(this.cartsController.rename({cartId:cart.id.toString(),name: newTitle}))
      .then(
        () => {
          cart.title = newTitle;
          this.log.debug("Renamed cart", newTitle);
        }).catch(
        errorResponse => {
          if (errorResponse.stack.response.error === "The cart name is not unique") {
            this.noticeService.show('Carts_NameNotUnique', NoticeType.Error);
          } else {
            this.log.error("Failed renaming cart", newTitle);
          }
          this.refreshItems();
        });
  }

  trackByCartId(index: number, cart: CartListItem) {
    return cart.id;
  }

  refreshItems() {

    this.errorFetchingItems = false;

    switch (this.type) {
    case 'privateTemplate': {
      this.allowEditTitle = true;
      return this.fetchTemplates(CartType.PrivateTemplate);
    }
    case 'sharedTemplate': {
      this.allowEditTitle = true;
      return this.fetchTemplates(CartType.SharedTemplate);
    }
    case 'orders': {
      return this.fetchOrders();
    }
    case 'quotations': {
      return this.fetchOrders();
    }
    default: {

      break;
    }
    }

    if (!this.type) {
      return;
    }

    this.isLoading = true;
    this.cartList = [];

    const refinementOptions: PagedUserCartsRefinementOptions = {
      skip: this.skip,
      take: this.pageSize,
      from: this.currentFilters.from?.toDateString(),
      to: this.currentFilters.to?.toDateString(),
      query: this.currentFilters.query,
      cartStatus: this.currentFilters.status,
      sortBy:null
    };

    firstValueFrom(this.cartsController.getUsersCartsWithStatus({body:<CartForListRequest>{options: refinementOptions, statuses: <CartStatus[]>this.statuses}}))
      .then((d: CartForListViewModelPagedResponse) => {
        this.log.debug('cartItems', d);
        this.totalItems = d.totalCount;

        this.cartList = d.items.map<CartListItem>(fa => {
          return {
            id: fa.id,
            itemCount: fa.itemCount,
            title: fa.name,
            showItems: false,
            status: fa.status,
            properties: [
              { key: 'Global_Status', valueResourceKey: 'Carts_CartStatus_' + fa.status },
              { key: 'Global_Weight', value: this.formattingService.formatWeight(fa.totalWeight), valueSuffixResourceKey: 'Global_UoM_KG' },
              { key: 'Carts_Created', value: this.formattingService.formatDate(fa.createdDate) },
              { key: 'Global_CustomerNumber', value: fa.deliverySequence.businessRelation.erpCustomerID },
              { key: 'Global_DeliverySequence', value: fa.deliverySequence.number }
            ],
            type:fa.type
          };
        });


        this.isLoading = false;
      }).catch(err => {
        this.log.error('Error getting user carts with status', err);
        this.errorFetchingItems = true;
        this.isLoading = false;
      });
  }
}
